import requests from "./httpService";

const NotificationService = {
  getCompanyNotifications(id,body) {
    return requests.get(`/notification/all?company_id=${id}`, body);
  },
  markAllRead(id,body) {
    return requests.get(`/notification/readAll?company_id=${id}`, body);
  },
  readNotification(id,compid,body) {
    return requests.post(`/notification/readOne/${id}`, body);
  },
};

export default NotificationService;
