import requests from "./httpService";

const LeadSourceService = {
  getLeadSourceAll(id, body) {
    return requests.get(`/leadsource/all?company_id=${id}`, body);
  },
  statusChange(id, body) {
    return requests.post(`/leadsource/statusupdate/${id}`, body);
  },
  updateLeadSource(id, body) {
    return requests.post(`/leadsource/update/${id}`, body);
  },
  createLeadSource(body) {
    return requests.post(`/leadsource/create`, body);
  },
};

export default LeadSourceService;
