import requests from "./httpService";

const LeavetypeService = {
  getLeaveType(id, body) {
    return requests.get(`/leavetypes/all?company_id=${id}`, body);
  },
  createLeaveType(body) {
    return requests.post(`/leavetypes/create`, body);
  },
  updateLeaveType(id, body) {
    return requests.post(`/leavetypes/update/${id}`, body);
  },
  updateStatusLeaveType(id, body) {
    return requests.post(`/leavetypes/statusupdate/${id}`, body);
  },
  deleteLeaveType(id, body) {
    return requests.delete(`/leavetypes/${id}`, body);
  },
};

export default LeavetypeService;
