import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeavetypeService from "../../../Services/LeavetypeService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import RepeaterForm from "./Repeater/RepeaterForm";
import Select from "react-select";
import PolicyServices from "../../../Services/PolicyServices";

const AddPolicy = () => {
  const compid = DataParser.CompanyId();
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [title, setTitle] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [shiftStartTime, setShiftStartTime] = useState("");
  const [shiftEndTime, setShiftEndTime] = useState("");
  const [lateComingLenency, setLateComingLenency] = useState("");
  const [earlyArival, setEarlyArival] = useState("");
  const [forceTime, setForceTime] = useState("");
  const [lateComingMontlyBudget, setLateComingMontlyBudget] = useState("");
  const [lateComersPlenty, setLateComersPlenty] = useState("");
  const [dailyOvertime, setDailyOvertime] = useState("");
  const [holidayOvertime, setHolidayOvertime] = useState("");
  const [overtimeRate, setOvertimeRate] = useState("");

  //fields

  const [inputFields, setInputFields] = useState([]);

  const addFields = () => {
    let newfield = {
      leave_type_id: "",
      leave_type_name: "",
      allowed_leaves: "",
      unit: "",
      carry_forword: "",
      consecutive_allowed: "",
      prorated: "",
      paid_leaves: "",
      encashable: "",
    };
    setInputFields([...inputFields, newfield]);
  };

  useEffect(() => {
    getAllLeaveType();
    getCompanyUsers();
  }, []);

  const getAllLeaveType = () => {
    LeavetypeService.getLeaveType(compid).then((res) => {
      const Data = res.data.leavetypes;
      setLeaveTypeList(Data);
    });
  };

  const getCompanyUsers = () => {
    PolicyServices.getUsersCheck(compid).then((res) => {
      const Data = res.data.users;
      let newData = [];

      for (let i = 0; i < Data.length; i++) {
        let obj = {
          value: Data[i].id,
          label: `${Data[i].first_name} ${Data[i].last_name}`,
        };
        newData.push(obj);
      }
      setUsersList(newData);
    });
  };

  const goNextTab = (e) => {
    e.preventDefault();

    document
      .querySelector(".nav-tabs-custom > .nav-item.custome")
      .nextElementSibling.classList.add("custome");
    document
      .querySelector(".nav-tabs-custom > .nav-item.custome")
      .nextElementSibling.childNodes[0].click();
    document
      .querySelector(".nav-tabs-custom > .nav-item.custome")
      .nextElementSibling.childNodes[0].classList.add("active");
    document
      .querySelector(".nav-tabs-custom > .nav-item")
      .classList.remove("custome");
  };

  const submitPolicy = (e) => {
    e.preventDefault();
    let users = [];
    for (let i = 0; i < selectedUsers.length; i++) {
      users.push(selectedUsers[i].value);
    }

    const Body = {
      company_id: compid,
      name: title,
      assinged_to: users,
      leave_policy: inputFields,
      attendence_policy: {
        shift_start_time: DataParser.convertTime(shiftStartTime),
        late_coming_time_leniency: lateComingLenency,
        early_arrival: earlyArival,
        shift_closing_time: DataParser.convertTime(shiftEndTime),
        force_timeout: forceTime,
        late_coming_monthly_bucket: lateComingMontlyBudget,
        late_coming_penalty: lateComersPlenty,
        daliy_overtime: dailyOvertime,
        holiday_overtime: holidayOvertime,
        overtime_rate: overtimeRate,
      },
    };

    PolicyServices.createHRPolicy(Body).then((res) =>
      console.log("res: ", res)
    );
  };
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Policy</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card-body">
                  {/* Nav tabs */}
                  <ul
                    className="nav nav-tabs nav-tabs-custom nav-justified"
                    role="tablist"
                  >
                    <li className="nav-item custome">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#brief"
                        role="tab"
                        aria-selected="true"
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home" />
                        </span>
                        <span className="d-none d-sm-block">Brief</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#leavepolicy"
                        role="tab"
                        aria-selected="false"
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user" />
                        </span>
                        <span className="d-none d-sm-block">Leaves Policy</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#attandancepolicy"
                        role="tab"
                        aria-selected="false"
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope" />
                        </span>
                        <span className="d-none d-sm-block">
                          Attendance Policy
                        </span>
                      </a>
                    </li>
                  </ul>
                  {/* Tab panes */}
                  <div className="tab-content p-3 text-muted">
                    <div className="tab-pane active" id="brief" role="tabpanel">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom01"
                            >
                              Policy Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="First name"
                              required
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                            <div className="valid-feedback">Looks good!</div>
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ placeSelf: "center" }}
                        >
                          <div className="mb-3">
                            <label
                              className="form-label invisible"
                              htmlFor="validationCustom01"
                            >
                              Policy Title
                            </label>
                            <div className="form-check form-switch " dir="ltr">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitch1"
                                checked={isActive}
                                onChange={(e) => setIsActive(e.target.checked)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customSwitch1"
                              >
                                Activate
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ placeSelf: "center" }}
                        >
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom01"
                            >
                              Select Users
                            </label>

                            <Select
                              options={usersList}
                              isMulti
                              onChange={(e) => setSelectedUsers(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col text-right">
                        <Link
                          onClick={(e) => goNextTab(e)}
                          className="btn btn-color continue"
                          to="#"
                        >
                          Next
                        </Link>
                      </div>
                    </div>
                    <div className="tab-pane" id="leavepolicy" role="tabpanel">
                      <div className="col mb-3" style={{ textAlign: "right" }}>
                        <button
                          type="button"
                          data-repeater-create=""
                          onClick={addFields}
                          className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 add_new_user"
                        >
                          <i className="icon-plus4" />
                          <span>+</span> Add Leave Types
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <RepeaterForm
                            inputFields={inputFields}
                            setInputFields={setInputFields}
                            leaveTypeList={leaveTypeList}
                          />
                        </div>
                        <div className="col text-right">
                          <Link
                            onClick={(e) => goNextTab(e)}
                            className="btn btn-color continue"
                            to="#"
                          >
                            Next
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="attandancepolicy"
                      role="tabpanel"
                    >
                      <div className="row mb-3 mt-4">
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom01"
                          >
                            Shift Start Time
                          </label>
                          <input
                            type="time"
                            id="validationCustom01"
                            className="form-control"
                            value={shiftStartTime}
                            onChange={(e) => setShiftStartTime(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom10"
                          >
                            Shift End Time
                          </label>
                          <input
                            type="time"
                            id="validationCustom10"
                            className="form-control"
                            value={shiftEndTime}
                            onChange={(e) => setShiftEndTime(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom02"
                          >
                            Late Coming Leniency Time
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            placeholder="Late Coming Leniency Time"
                            value={lateComingLenency}
                            onChange={(e) =>
                              setLateComingLenency(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom03"
                          >
                            Early Arrival Policy
                          </label>
                          <select
                            id="validationCustom03"
                            className="form-select"
                            value={earlyArival}
                            onChange={(e) => setEarlyArival(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            <option value="shittime">Shift Time</option>
                            <option value="attandancetime">
                              Attendance Time
                            </option>
                          </select>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom04"
                          >
                            Force Timeout
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom04"
                            placeholder="Force Timeout"
                            value={forceTime}
                            onChange={(e) => setForceTime(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom05"
                          >
                            Late Coming Monthly Bucket
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom05"
                            placeholder="Late Coming Monthly Bucket"
                            value={lateComingMontlyBudget}
                            onChange={(e) =>
                              setLateComingMontlyBudget(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom06"
                          >
                            Late Comers Penalty
                          </label>
                          <input
                            type="number"
                            step="0.1"
                            className="form-control"
                            id="validationCustom06"
                            placeholder="e.g .25 day or .5 day"
                            value={lateComersPlenty}
                            onChange={(e) =>
                              setLateComersPlenty(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom07"
                          >
                            Daily Overtime (paid / unpaid)
                          </label>
                          <select
                            value={dailyOvertime}
                            onChange={(e) => setDailyOvertime(e.target.value)}
                            id="validationCustom07"
                            className="form-select"
                          >
                            <option value="">Please Select</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">Unpaid</option>
                          </select>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom08"
                          >
                            Holiday Overtime (paid / unpaid)
                          </label>
                          <select
                            id="validationCustom08"
                            className="form-select"
                            value={holidayOvertime}
                            onChange={(e) => setHolidayOvertime(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">Unpaid</option>
                          </select>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label
                            className="form-label"
                            htmlFor="validationCustom09"
                          >
                            Overtime rate
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom09"
                            placeholder="Overtime rate"
                            value={overtimeRate}
                            onChange={(e) => setOvertimeRate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col text-right">
                        <Link
                          onClick={(e) => submitPolicy(e)}
                          className="btn btn-color continue"
                          to="#"
                        >
                          Submit
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPolicy;
