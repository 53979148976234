import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TaskServices from "../../../Services/TaskServices";
import DataParser from "../../../utlits/Dataparser";

const CreateTask = (props) => {
  const {
    setDrawerOpen,
    priority,
    statuses,
    handelDrawer,
    taskOwner,
    reFreshData,
    id,
    labelledby,
  } = props;

  const [title, setTitle] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [periority, setPeriority] = useState("");
  const [approval, setApproval] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  const companyId = DataParser.CompanyId();
  const companyIdentifier = DataParser.CompanyIdentifier();
  useEffect(() => {
    const userId = DataParser.UserId();
    const datee = DataParser.getDateinFormat();
    setDueDate(datee);
    setStatus("pending");
    setApproval(0);
    setAssignedTo(userId);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      title: title,
      assign_to: assignedTo,
      due_date: dueDate,
      priority: periority,
      approval_required: approval,
      status: status,
      description: description,
    };
    handelDrawer(e);
    TaskServices.createTask(body)
      .then((res) => {
        const datee = DataParser.getDateinFormat();
        handelDrawer(e);
        setTitle("");
        setAssignedTo("");
        setDueDate(datee);
        setPeriority("");
        setApproval("");
        setStatus("");
        setDescription("");
        reFreshData(e);
        var link = document.getElementById("taskClose");
        link.click();
      })
      .catch((err) => console.log("Error Failed"));
  };

  const addAttachment = (e) => {
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    formdata.append("company_id", companyId);
    formdata.append("company_identifier", companyIdentifier);
    const id = 0;
    if (e.target.files[0]) {
      TaskServices.createTaskAttatchment(id, formdata);
    }
  };

  const onDateChange = (date) => {
    setDueDate(date);
  };
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={id}
        aria-labelledby={labelledby}
        style={{ width: "650px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>Tasks</h5>
          <button
            type="button"
            id="taskClose"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
              <div style={{paddingRight: '2rem'}}  className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <div className="controls row">
                      <div className="col-sm-12 title_font_size">
                        <input
                          type="text"
                          id="titleid"
                          className="form-control w-100 inputset custominputTitle canvous-title-field"
                          placeholder="Title"
                          style={{ border: "0px" }}
                          name="title"
                          required
                          data-validation-required-message="This role field is required"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Assigned to<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        style={{ border: "0px" }}
                        name="lead_owner"
                        id="basicSelect"
                        required
                        value={assignedTo}
                        onChange={(e) => setAssignedTo(e.target.value)}
                      >
                        <option value="">Select Option</option>
                        {taskOwner &&
                          taskOwner.map((el) => (
                            <option key={el.id} value={el.id}>
                              {assignedTo === el.id
                                ? "Assign me"
                                : el.first_name + " " + el.last_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="due_date">
                        Due Date
                      </label>
                    </div>
                    <div className="col-md-9">
                      <div className="date-picker">
                        <DatePicker
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          id="due_date"
                          bordered={false}
                          allowClear={false}
                          value={moment(dueDate)}
                          onChange={onDateChange}
                          format={"DD-MM-YYYY"}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="Periority">
                        Periority <span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        style={{ border: "0px" }}
                        name="lead_owner"
                        id="Periority"
                        required
                        value={periority}
                        onChange={(e) => setPeriority(e.target.value)}
                      >
                        <option value="">Select Option</option>
                        {priority &&
                          priority.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="Approval">
                        Approval Required<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        style={{ border: "0px" }}
                        name="lead_owner"
                        id="Approval"
                        required
                        value={approval}
                        onChange={(e) => setApproval(e.target.value)}
                      >
                        <option value="">Select Option</option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="taskStatus">
                        Task Status<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        style={{ border: "0px" }}
                        name="lead_owner"
                        id="taskStatus"
                        required
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="">Select Option</option>
                        {statuses &&
                          statuses.map((el) => (
                            <option key={el} value={el}>
                              {el}
                            </option>
                          ))}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset
                    className="form-group row mt-2"
                    style={{ marginRight: "0px" }}
                  >
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="Description">
                        Description
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control w-100 autosize textset canvous-textarea-field canvous-textarea-field-edit"
                        rows={3}
                        placeholder="Details"
                        id="Description"
                        name="details"
                        data-validation-required-message="This role field is required"
                        data-gramm="false"
                        wt-ignore-input="true"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                  <button
                    style={{ marginRight: "8px" }}
                    type="submit"
                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateTask;
