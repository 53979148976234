import requests from "./httpService";

const LeadsServices = {
  getLeads(id, body) {
    return requests.get(`/leads/all?company_id=${id}`, body);
  },
  createLead(body) {
    return requests.post(`/leads/create`, body);
  },
  createCustomer(body) {
    return requests.post(`/customers/create`, body);
  },
  updateLeadSource(id, body) {
    return requests.post(`/leads/updateleadsource/${id}`, body);
  },
  createleadsAttatchment(body) {
    return requests.post(`/lead-attachment/0`, body);
  },
  leadsAttatchment(id, body) {
    return requests.post(`/lead-attachment/${id}`, body);
  },
  leadsNotes(id, body) {
    return requests.post(`/lead-note/${id}`, body);
  },
  updateLeadStatus(id, body) {
    return requests.post(`/leads/updateleadstatus/${id}`, body);
  },
  updateLeadAssigne(id, body) {
    return requests.post(`/leads/updateasignee/${id}`, body);
  },
  updateLeadDueDate(id, body) {
    return requests.post(`/leads/updateleadduedate/${id}`, body);
  },
  displayLeadNote(id, body) {
    return requests.post(`/leads/showleadnote/${id}`, body);
  },
  viewLead(id, compid, body) {
    return requests.post(`/leads/view/${id}`, body);
  },
  viewLeadCategoryPackage(id, companyId, body) {
    return requests.get(`/category-packages/all/${id}`, body);
  },
  leadNoteDelete(id, body) {
    return requests.post(`/leads/deletenote/${id}`, body);
  },
  updateLead(id, body) {
    return requests.post(`/leads/update/${id}`, body);
  },
  deleteLead(id, body) {
    return requests.delete(`/leads/${id}`, body);
  },
  pinToTop(id, body) {
    return requests.post(`/leads/updatenotepin/${id}`, body);
  },
  noteUpdate(id, body) {
    return requests.post(`/leads/updatenote/${id}`, body);
  },
  noteDekete(id, body) {
    return requests.get(`/leads/lead-note-delete/${id}`, body);
  },
  updateLeadTitle(id, body) {
    return requests.post(`/leads/update-lead-title/${id}`, body);
  },
};

export default LeadsServices;
