import React, { useState, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import "react-loading-skeleton/dist/skeleton.css";
import PhyicalYearService from "../../../Services/PhyicalYearService";

const LeavePeriod = () => {
  const [isLoading, setIsLoading] = useState(false);
  const compid = DataParser.CompanyId();
  const [seasionsList, setSeasonList] = useState([]);

  useEffect(() => {
    getLeaveSession();
  }, []);

  const getLeaveSession = () => {
    setIsLoading(true);
    PhyicalYearService.getPhysicalYear(compid).then((res) => {
      const data = res.data.Settings;
      setSeasonList(data);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Helmet>
        <title>Leaves Period - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leaves Period</h4>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0 table-striped">
                      <thead>
                        <tr className="tableHeaderbg">
                          <th>Sr#</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {seasionsList &&
                          seasionsList.map((item) => (
                            <tr key={item.id}>
                              <th scope="row">{item.id}</th>
                              <th scope="row">
                                {moment(item.start_date, "YYYY/MM/DD").format(
                                  "YYYY/MM/DD"
                                )}
                              </th>
                              <th scope="row">
                                {moment(item.end_date, "YYYY/MM/DD").format(
                                  "YYYY/MM/DD"
                                )}
                              </th>
                              <th scope="row">
                                {item.status === 1 ? (
                                  <span className="btn btn-success waves-effect waves-light btn-sm">
                                    Active
                                  </span>
                                ) : (
                                  <span className="btn btn-danger waves-effect waves-light btn-sm">
                                    Deactive
                                  </span>
                                )}
                              </th>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeavePeriod;
