const ImageUtlitsUsers = {
  ImageSetting(image) {
    const index = image?.lastIndexOf(".");
    const after = image?.slice(index + 1);
    let imagesFormat = ["png", "jpg", "jpeg", "gif"];
    const check = imagesFormat?.includes(after);
    if (check) {
      return (
        <>
          <img
            src={process.env.REACT_APP_USER_DOCS_PATH + image}
            className="wh-100"
            width={100}
            height={50}
            alt=""
          />
        </>
      );
    } else {
      return (
        <>
          <img
            src={process.env.REACT_APP_USER_DOCS_PATH + after + `.png`}
            className="wh-100"
            width={100}
            height={50}
            alt=""
          />
        </>
      );
    }
  },
};

export default ImageUtlitsUsers;
