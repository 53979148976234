import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import StatusBadge from "../../Reuseable/StatusBadge";
import LeaveTypeService from "../../../Services/LeavetypeService";
import DataParser from "../../../utlits/Dataparser";
import LeavetypeService from "../../../Services/LeavetypeService";

const LeaveType = () => {
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [status, setStatus] = useState(true);
  const [name, setName] = useState("");
  const [isEntitlementSituation, setIsEntitlementSituation] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  const [editName, setEditName] = useState("");
  const [isEditEntitlementSituation, setIsEditEntitlementSituation] =
    useState(null);
  const compid = DataParser.CompanyId();

  useEffect(() => {
    getAllLeaveType();
  }, []);

  const getAllLeaveType = () => {
    LeaveTypeService.getLeaveType(compid).then((res) => {
      const Data = res.data.leavetypes;
      setLeaveTypeList(Data);
    });
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const onEditStatusChange = () => {
    setEditStatus(!editStatus);
  };

  const handelDelete = (e, item) => {
    e.preventDefault();
    const newArray = leaveTypeList.filter((s) => s.id !== item.id);
    setLeaveTypeList(newArray);
    LeaveTypeService.deleteLeaveType(item.id).then((res) => {
      getAllLeaveType();
    });
  };

  const handelEdit = (e, item) => {
    setEditStatus(item.status);
    setIsEditEntitlementSituation(item.isEntitlementSituation);
    setEditName(item.name);
  };

  const handelCreate = (e) => {
    e.preventDefault();
    const Data = {
      company_id: compid,
      name: name,
      situational: isEntitlementSituation,
    };
    const link = document.getElementById("createmodalclose");
    LeaveTypeService.createLeaveType(Data).then((res) => {
      link.click();
      setName("");
      setIsEntitlementSituation(false);
      getAllLeaveType();
    });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Leaves Type</h4>
                  <div className="page-title-right">
                    <>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#createLeaveTypeModal"
                      >
                        Create Leave Type
                      </button>
                      {/* Create modal content */}
                      <div
                        id="createLeaveTypeModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Leave Type
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                id="createmodalclose"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <form onSubmit={(e) => handelCreate(e)}>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12 mb-3 d-flex">
                                    <label
                                      className="form-label"
                                      style={{ paddingRight: "20px" }}
                                      htmlFor="Name"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Name"
                                      placeholder="Title"
                                      required
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <label
                                      className="form-label"
                                      style={{ paddingRight: "20px" }}
                                      htmlFor="Name"
                                    >
                                      Is Entitlement Situation?
                                    </label>
                                    <div
                                      className="form-check"
                                      style={{ paddingRight: "10px" }}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        onClick={() =>
                                          setIsEntitlementSituation(true)
                                        }
                                        checked={
                                          isEntitlementSituation === true
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="formCheck1"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck2"
                                        onClick={() =>
                                          setIsEntitlementSituation(false)
                                        }
                                        checked={
                                          isEntitlementSituation === false
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="formCheck2"
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <label
                                      className="form-label"
                                      style={{ paddingRight: "20px" }}
                                      htmlFor="Name"
                                    >
                                      Status
                                    </label>
                                    <div
                                      className="form-check form-switch form-switch-md mb-3"
                                      dir="ltr"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd"
                                        onChange={(e) => onStatusChange(e)}
                                        defaultChecked={status}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  onClick={(e) => handelCreate(e)}
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                        <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Name</th>
                            <th>Is Entitlement Situation</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leaveTypeList &&
                            leaveTypeList.map((item, index) => (
                              <tr key={item.id}>
                                <th className="tableset" scope="row">{index + 1}</th>
                                <th className="tableset">{item.name}</th>
                                <th className="tableset">
                                  {item.situational === true ||
                                  item.situational === 1 ? (
                                    <span className="btn btn-success waves-effect waves-light btn-sm">
                                      Yes
                                    </span>
                                  ) : (
                                    <span className="btn btn-danger waves-effect waves-light btn-sm">
                                      No
                                    </span>
                                  )}
                                </th>
                                <th className="tableset">
                                  <StatusBadge
                                    item={item}
                                    api={LeaveTypeService.updateStatusLeaveType}
                                  />
                                </th>
                                <th className="tableset">
                                  {/* <Link
                                    to="#"
                                    className="cursor-pointer iconscolor"
                                    onClick={(e) => handelDelete(e, item)}
                                  >
                                    <i className="uil-trash iconscolor" />
                                  </Link> */}

                                  <Link
                                    to="#"
                                    className="cursor-pointer iconscolor"
                                    onClick={(e) => handelEdit(e, item)}
                                  >
                                    <i
                                      className="uil-pen iconscolor"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editLeaveTypeModal"
                                    />
                                  </Link>
                                </th>
                              </tr>
                            ))}
                          {/* edit modal content */}
                          <div
                            id="editLeaveTypeModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="myModalLabel">
                                    Edits Leave Type
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-12 mb-3 d-flex">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Title"
                                        required
                                        value={editName}
                                        onChange={(e) =>
                                          setEditName(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="col-12 mb-3 d-flex">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Is Entitlement Situation?
                                      </label>
                                      <div
                                        className="form-check"
                                        style={{ paddingRight: "10px" }}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="formCheck1"
                                          onClick={() =>
                                            setIsEditEntitlementSituation(true)
                                          }
                                          checked={
                                            isEditEntitlementSituation === true
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="formCheck1"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="formCheck2"
                                          onClick={() =>
                                            setIsEditEntitlementSituation(false)
                                          }
                                          checked={
                                            isEditEntitlementSituation === false
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="formCheck2"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 mb-3 d-flex">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Status
                                      </label>
                                      <div
                                        className="form-check form-switch form-switch-md mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          onChange={(e) =>
                                            onEditStatusChange(e)
                                          }
                                          defaultChecked={editStatus}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-light waves-effect"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                              {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                          </div>
                          {/* /.modal */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveType;
