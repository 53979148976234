import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import makeAnimated from "react-select/animated";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import LeavetypeService from "../../../Services/LeavetypeService";
import LeaveSessionService from "../../../Services/LeaveSessionService";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import shortid from "shortid";

const LeaveEntitlement = () => {
  const SelectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#00b5b8" : null,
        color: isFocused ? "#fff" : "#333333",

        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#00b5b8"
              : "#00b5b8"
            : undefined,
        },
      };
    },
  };
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leavePeriod, setLeavePeriod] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [LeaveEntitlementList, setLeaveEntitlementList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [leavePeriodList, setLeavePeriodList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [entitlementVal, setEntitlementVal] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [selectedLeavePeriod, setSelectedLeavePeriod] = useState("");
  const compid = DataParser.CompanyId();
  const userid = DataParser.UserId();

  useEffect(() => {
    getLeaveEntitlements();
    getCompanyUsers();
    getAllLeaveType();
    getLeaveSession();
  }, []);

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(compid).then((res) => {
      const Data = res.data.user;
      let newData = [];

      for (let i = 0; i < Data.length; i++) {
        let obj = { value: Data[i].id, label: Data[i].first_name };
        newData.push(obj);
      }
      setUsersList(newData);
    });
  };

  const getAllLeaveType = () => {
    LeavetypeService.getLeaveType(compid).then((res) => {
      const Data = res.data.leavetypes;
      const activeTypes = Data.filter((s) => s.status === 1);
      let newData = [];

      for (let i = 0; i < activeTypes.length; i++) {
        let obj = { value: activeTypes[i].id, label: activeTypes[i].name };
        newData.push(obj);
      }
      setLeaveTypeList(newData);
    });
  };

  const getLeaveSession = () => {
    LeaveSessionService.getLeaveSeassions(compid).then((res) => {
      const Data = res.data.Leave_sessions;
      let newData = [];

      for (let i = 0; i < Data.length; i++) {
        let obj = { value: Data[i].id, label: Data[i].title };
        newData.push(obj);
      }
      setLeavePeriodList(newData);
    });
  };

  const getLeaveEntitlements = () => {
    setLoading(true);
    LeaveEntitlementService.getEntitlementeEmployee(userid, compid).then(
      (res) => {
        const Data = res.data.entitlement;
        setLeaveEntitlementList(Data);
        setLoading(false);
      }
    );
  };

  const filterSearchForAdmin = (DataA) => {
    if (employeeName === "") {
      return DataA;
    }
    return DataA.filter((el) =>
      el.Employee.toLowerCase().includes(employeeName.toLowerCase())
    );
  };
  const filterbyType = (DataA) => {
    if (leaveType === "") {
      return DataA;
    }
    return DataA.filter((el) => el.LeaveType === leaveType);
  };
  const filterbyPeriod = (DataA) => {
    if (leavePeriod === "") {
      return DataA;
    }
    return DataA.filter((el) => el.LeavePeriod === leavePeriod);
  };
  const allFilter = (DataForAdmin) => {
    return filterbyPeriod(filterbyType(filterSearchForAdmin(DataForAdmin)));
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    let users = [];
    for (let i = 0; i < selectedUser.length; i++) {
      users.push(selectedUser[i].value);
    }

    const Body = {
      company_id: compid,
      entitlement: entitlementVal,
      empid: users,
      leavetype: selectedLeaveType,
      leavesession: selectedLeavePeriod,
    };
    const link = document.getElementById("EntitlementCreateClose");
    LeaveEntitlementService.createEntitlement(Body).then((res) => {
      link.click();
      setEntitlementVal("");
      getLeaveEntitlements();
    });
  };

  const handelViewUser = (e, item) => {
    e.preventDefault();
    navigate(
      `/${DataParser.CompanyIdentifierRole()}/leave_entitlement/${item}`
    );
  };

  return (
    <>
      <Helmet>
        <title>Leaves Entitlement - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leaves Entitlement</h4>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <table
                    className="table table-striped"
                  >
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Leave Type</th>
                        <th>Total</th>
                        <th>Remainings</th>
                        <th>Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {LeaveEntitlementList &&
                            LeaveEntitlementList.map((el, index) => (
                              <tr
                                key={shortid.generate()}
                                role="row"
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td className="tableset">{el.leaveType}</td>
                                <td className="tableset">{el.total}</td>
                                <td className="tableset">{el.remainings}</td>
                                <td className="tableset">{el.used}</td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveEntitlement;
