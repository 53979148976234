import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataParser from "../../utlits/Dataparser";
import Header from "./Header";

const Sidebar = () => {
  const [sideBarState, setSideBarState] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  const navigate = useNavigate();

  const handleChange = () => {
    setSideBarState(!sideBarState);
  };
  useEffect(() => {
    if (sideBarState === true) {
      document.body.classList.add("vertical-collpsed");
    } else {
      document.body.classList.remove("vertical-collpsed");
    }
  }, [sideBarState]);

  const [userPermissions, setuserPermissions] = useState([]);
  const [usertype, setusertype] = useState("");
  useEffect(() => {
    const usertyp = DataParser.UserType();
    setusertype(usertyp);
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const handleToogleMenu = () => {
    setOpenMenu(!openMenu);
    setOpenMenu2(false);
  };
  const handleToogleMenu2 = () => {
    setOpenMenu2(!openMenu2);
    setOpenMenu(false);
  };

  return (
    <>
      <Header handleChange={handleChange} />
      <div className="vertical-menu">
        {/* LOGO */}
        <div className="navbar-brand-box">
          {DataParser.CompanyId() === 0 ? (
            <>
              {" "}
              <Link
                to={`/${DataParser.CompanyIdentifierRole()}/dashboard`}
                className="logo logo-light"
              >
                <span className="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo-light.png" alt="" height="20" />
                </span>
              </Link>
            </>
          ) : (
            <Link
              to={`/${DataParser.CompanyIdentifierRole()}/home`}
              className="logo logo-light"
            >
              <span className="logo-sm">
                <img src="/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo-light.png" alt="" height="20" />
              </span>
            </Link>
          )}
        </div>
        <button
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
          onClick={handleChange}
        >
          <i className="fa fa-fw fa-bars" />
        </button>
        <div data-simplebar="" className="sidebar-menu-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu list-unstyled" id="side-menu">
              {usertype === "user" ? (
                <>
                  <li className="menu-title">General</li>
                  <li>
                    <Link
                      to={
                        DataParser.CompanyIdentifier() === "null"
                          ? "/admin/dashboard"
                          : `/${DataParser.CompanyIdentifierRole()}/dashboard`
                      }
                    >
                      <i className="uil-home-alt" />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  {userPermissions.indexOf("package-list") !== -1 ? (
                    <li>
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/packages`}
                      >
                        <i className="uil-home-alt" />
                        <span>Packages</span>
                      </Link>
                    </li>
                  ) : null}
                  {userPermissions.indexOf("companies-list") !== -1 ? (
                    <li>
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/companies`}
                      >
                        <i className="uil-folder-plus" />
                        <span>Companies</span>
                      </Link>
                    </li>
                  ) : null}
                  {userPermissions.indexOf("companies-list") !== -1 ? (
                    <li>
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/modules`}
                      >
                        <i className="uil-folder-plus" />
                        <span>Modules</span>
                      </Link>
                    </li>
                  ) : null}
                  {userPermissions.indexOf("user-list") !== -1 ? (
                    <li>
                      <Link to={`/${DataParser.CompanyIdentifierRole()}/users`}>
                        <i className="uil-user" />
                        <span>Users</span>
                      </Link>
                    </li>
                  ) : null}
                  {userPermissions.indexOf("role-list") !== -1 ? (
                    <li>
                      <Link to={`/${DataParser.CompanyIdentifierRole()}/roles`}>
                        <i className="uil-folder-plus" />
                        <span>Roles</span>
                      </Link>
                    </li>
                  ) : null}
                  {userPermissions.indexOf("departments-list") !== -1 ? (
                    <li>
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/departments`}
                      >
                        <i className="uil-square-full" />
                        <span>Departments</span>
                      </Link>
                    </li>
                  ) : null}
                </>
              ) : (
                <>
                  <li className="menu-title">Company Users</li>
                  <li>
                    <Link to={`/${DataParser.CompanyIdentifierRole()}/home`}>
                      <i className="uil-home-alt" />
                      <span>Home</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/employe-attendance`}
                    >
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <span>Attendance</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/employee_leave_entitlement`}
                    >
                      <i className="fa fa-bookmark" aria-hidden="true"></i>
                      <span>Leave Entitlement</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/employee_leaveuser`}
                    >
                      <i className="fa fa-id-badge" aria-hidden="true"></i>
                      <span>User Leaves</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/employe_leaves`}
                    >
                      <i className="fa fa-id-badge" aria-hidden="true"></i>
                      <span>Leaves</span>
                    </Link>
                  </li>
                  {userPermissions.indexOf("tasks-list") !== -1 ? (
                    <li>
                      <Link to={`/${DataParser.CompanyIdentifierRole()}/tasks`}>
                        <i className="uil-list-ul" />
                        <span>My Tasks</span>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* {userPermissions.indexOf("projects-list") !== -1 ? (
                    <li>
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/projects`}
                      >
                        <i className="uil-paperclip" />
                        <span>My Projects</span>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )} */}
                  {/* {userPermissions.indexOf("work-flow-list") !== -1 ? (
                    <li>
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/workflow`}
                      >
                        <i className="uil-paperclip" />
                        <span>My Workflows</span>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )} */}
                  {userPermissions.indexOf("leads-list") !== -1 ? (
                    <li>
                      <Link to={`/${DataParser.CompanyIdentifierRole()}/leads`}>
                        <i className="uil-arrow-growth" />
                        <span>My Leads</span>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <li className={openMenu ? "mm-active" : null}>
                    <Link
                      to="#"
                      className="has-arrow waves-effect"
                      aria-expanded="false"
                      onClick={() => handleToogleMenu()}
                    >
                      <i className="uil-user" />
                      <span>Setup</span>
                    </Link>
                    <ul
                      className={
                        openMenu
                          ? "sub-menu mm-collapse mm-show"
                          : "sub-menu mm-collapse"
                      }
                      style={openMenu ? null : { height: 0 }}
                    >
                      {userPermissions.indexOf("departments-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/departments`}
                          >
                            <i className="uil-building" />
                            Departments
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {userPermissions.indexOf("categories-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/categories`}
                          >
                            <i className="uil-object-ungroup" />
                            Categories
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {userPermissions.indexOf("user-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/users`}
                          >
                            <i className="uil-users-alt" />
                            All Users
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {userPermissions.indexOf("jobstatus-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/jobemplaoye`}
                          >
                            <i className="uil-bag" />
                            Employee job status
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {userPermissions.indexOf("role-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/roles`}
                          >
                            <i className="uil-analysis" />
                            Roles/Permssions
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      
                      {userPermissions.indexOf("communication-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/communication`}
                          >
                            <i className="uil-square" />
                            Communication Resource
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {userPermissions.indexOf("priority-list") !== -1 ? (
                        <li>
                          <Link
                            to={`/${DataParser.CompanyIdentifierRole()}/priority`}
                          >
                            <i className="uil-angle-double-up" />
                            Priority
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/lead_source`}
                        >
                          <i className="uil-building" />
                          Lead Source
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${DataParser.CompanyIdentifierRole()}/leave_type`}
                        >
                          <i className="uil-building" />
                          Lead Type
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  {DataParser.userRoleTitle() === "HR" ? (
                    <>
                      <li className={openMenu2 ? "mm-active" : null}>
                        <Link
                          to="#"
                          className="has-arrow waves-effect"
                          aria-expanded="false"
                          onClick={() => handleToogleMenu2()}
                        >
                          <i className="uil-user" />
                          <span>Human Resource</span>
                        </Link>
                        <ul
                          className={
                            openMenu2
                              ? "sub-menu mm-collapse mm-show"
                              : "sub-menu mm-collapse"
                          }
                          style={openMenu2 ? null : { height: 0 }}
                        >
                          <li>
                            <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/leaveusers`}
                            >
                              <i className="uil-user" />
                              All Employees
                            </Link>
                            {userPermissions.indexOf("attandence-list") !==
                            -1 ? (
                              <Link
                                to={`/${DataParser.CompanyIdentifierRole()}/hr`}
                              >
                                <i className="uil-users-alt" />
                                Attendance
                              </Link>
                            ) : null}
                            <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/leaves`}
                            >
                              <i className="uil-file-landscape-alt" />
                              Leaves Request
                            </Link>

                            {/* <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/leave_type`}
                            >
                              <i className="uil-building" />
                              Leaves Type
                            </Link> */}
                            {/* <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/leave_period`}
                            >
                              <i className="uil-building" />
                              Leaves Seasions
                            </Link> */}
                            {/* <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/leave_entitlement`}
                            >
                              <i className="uil-building" />
                              Leaves Entitlement
                            </Link> */}
                            <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/policy`}
                            >
                              <i className="uil-file-landscape-alt" />
                              HR Policy
                            </Link>

                            {/* {userPermissions.indexOf("jobstatus-list") !==
                            -1 ? (
                              <Link
                                to={`/${DataParser.CompanyIdentifierRole()}/jobemplaoye`}
                              >
                                <i className="uil-bag" />
                                Employee job status
                              </Link>
                            ) : null}

                            {userPermissions.indexOf("user-list") !== -1 ? (
                              <Link
                                to={`/${DataParser.CompanyIdentifierRole()}/users`}
                              >
                                <i className="uil-users-alt" />
                                All Users
                              </Link>
                            ) : null}

                            {userPermissions.indexOf("departments-list") !==
                            -1 ? (
                              <Link
                                to={`/${DataParser.CompanyIdentifierRole()}/departments`}
                              >
                                <i className="uil-building" />
                                Departments
                              </Link>
                            ) : null} */}
                          </li>
                          {/* <li>
                            <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/holidays`}
                            >
                              <i className="uil-building" />
                              Holidays
                            </Link>
                          </li> */}
                        </ul>
                      </li>{" "}
                    </>
                  ) : null}
                  {DataParser.isHead() === "yes" ? (
                    <>
                      <li className={openMenu2 ? "mm-active" : null}>
                        <Link
                          to="#"
                          className="has-arrow waves-effect"
                          aria-expanded="false"
                          onClick={() => handleToogleMenu2()}
                        >
                          <i className="uil-user" />
                          <span>Heads</span>
                        </Link>
                        <ul
                          className={
                            openMenu2
                              ? "sub-menu mm-collapse mm-show"
                              : "sub-menu mm-collapse"
                          }
                          style={openMenu2 ? null : { height: 0 }}
                        >
                          <li>
                            <Link
                              to={`/${DataParser.CompanyIdentifierRole()}/leaves_head`}
                            >
                              <i className="uil-file-landscape-alt" />
                              Leaves Request
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : null}

                  {DataParser.userRoleTitle() === "HR" ||
                  DataParser.userRoleTitle() === "Company Admin" ? (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={`/${DataParser.CompanyIdentifierRole()}/settings`}
                      >
                        <i className="uil-cog" />
                        <span className="align-middle">Settings</span>
                      </Link>
                    </li>
                  ) : null}
                </>
              )}
            </ul>
          </div>
          {/* Sidebar */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
