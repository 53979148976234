import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataParser from "../../utlits/Dataparser";
import NotificationService from "../../Services/NotificationService";
import moment from "moment";
import UserProfile from "./UserProfile.";
import secureLocalStorage from "react-secure-storage";

const Notofication = () => {
  const [notificationsList, setNotificationsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const companyid = DataParser.CompanyId();
    NotificationService.getCompanyNotifications(companyid).then((res) => {
      const data = res.data.notifications;
      setNotificationsList(data);
    });
  }, []);

  const getAllNotifications = () => {
    const companyid = DataParser.CompanyId();
    NotificationService.getCompanyNotifications(companyid).then((res) => {
      const data = res.data.notifications;
      setNotificationsList(data);
      if (data === "Un-Athunticated request") {
        secureLocalStorage.clear();
        navigate("/");
      }
    });
  };

  const handelAllRead = (e) => {
    e.preventDefault();
    const companyid = DataParser.CompanyId();
    NotificationService.markAllRead(companyid).then((res) => {
      if (res.Good) {
        setNotificationsList([]);
      }
    });
  };

  const readNotification = (e, item) => {
    e.preventDefault();
    const type = item.typ;
    const notificationId = item.id;
    const taskId = item.task_id;
    const leadId = item.task_id;
    const companyIdetifier = DataParser.CompanyIdentifier();
    const companyId = DataParser.CompanyId();
    if (type === "task") {
      readNotificationById(notificationId, companyId);
      navigate(`/${companyIdetifier}/tasks`, {
        state: {
          TaskId: taskId,
          isNotification: true,
        },
      });
    } else if (type === "project") {
      readNotificationById(notificationId, companyId);
      navigate(`/${companyIdetifier}/projects`, {
        state: {
          TaskId: taskId,
          isNotification: true,
        },
      });
    } else {
      readNotificationById(notificationId, companyId);
      navigate(`/${companyIdetifier}/leads`, {
        state: {
          LeadId: leadId,
          isNotification: true,
        },
      });
    }
  };

  const readNotificationById = (id, compid) => {
    NotificationService.readNotification(id, compid).then((res) => {
      if (res.Good) {
        getAllNotifications();
      }
    });
  };

  return (
    <>
      <div className="dropdown d-inline-block">
        <button
          type="button"
          className="btn header-item noti-icon waves-effect "
          id="page-header-notifications-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <i className="uil-bell" />
          <span className="badge bg-danger rounded-pill">
            {notificationsList?.length === 0 ? "" : notificationsList?.length}
          </span>
        </button>
        <div
          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 "
          aria-labelledby="page-header-notifications-dropdown"
          style={{
            position: "absolute",
            inset: "0px auto auto 0px",
            margin: 0,
            transform: "translate(-274px, 72px)",
          }}
          data-popper-placement="bottom-end"
        >
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="m-0 font-size-16"> Notifications </h5>
              </div>
              <div className="col-auto">
                <Link
                  to="#"
                  className="small"
                  onClick={(e) => handelAllRead(e)}
                >
                  Mark all as read
                </Link>
              </div>
            </div>
          </div>
          <div data-simplebar="init" style={{ maxHeight: 230 }}>
            <div className="simplebar-wrapper" style={{ margin: 0 }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer" />
              </div>
              <div className="simplebar-mask">
                <div
                  className="simplebar-offset"
                  style={{ right: "-17px", bottom: 0 }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    style={{ height: "auto", overflow: "hidden scroll" }}
                  >
                    {notificationsList?.length !== 0 ? (
                      <>
                        <div
                          className="simplebar-content"
                          style={{ padding: 0 }}
                        >
                          {notificationsList &&
                            notificationsList?.map((el) => (
                              <React.Fragment key={el.id}>
                                <Link
                                  to="#"
                                  className="text-reset notification-item"
                                  onClick={(e) => readNotification(e, el)}
                                >
                                  <div className="d-flex align-items-start">
                                    <div className="flex-shrink-0 me-3">
                                      <div className="avatar-xs">
                                        <UserProfile
                                          username={`${el?.assigned?.first_name} ${el?.assigned?.last_name}`}
                                          userImage={el?.assigned?.image}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-1">{el?.title}</h6>
                                      <div className="font-size-12 text-muted">
                                        <p className="mb-1"></p>
                                        <p className="mb-0">
                                          <i className="mdi mdi-clock-outline" />{" "}
                                          {moment(el?.created_at)
                                            .startOf()
                                            .fromNow()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </React.Fragment>
                            ))}
                        </div>
                      </>
                    ) : (
                      <div
                        className="flex-grow-1"
                        style={{ textAlign: "center" }}
                      >
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">No New Notifications</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: "auto", height: 359 }}
              />
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  display: "none",
                }}
              />
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: "visible" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  display: "block",
                  height: 147,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notofication;
