import React, { useState } from "react";
import { useEffect } from "react";
import DataParser from "../../utlits/Dataparser";

const LeadPendingField = (props) => {
  const { item, Status, Services, data, DefaultStatus } = props;
  const [DefaultStatuss, setDefaultStatus] = useState(DefaultStatus);
  useEffect(() => {
    setDefaultStatus(DefaultStatus);
  }, [item]);

  const handelChange = (e) => {
    e.preventDefault();
    setDefaultStatus(e.target.value);
    const id = item.id;
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();
    const dataLead = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      status: e.target.value,
    };
    Services(id, dataLead).then((res) => {
      data(e);
    });
  };

  return (
    <>
      <div className="form-group align-items-center position-relative">
        {DefaultStatuss === "pending-go-live" ? (
          <select
            className="form-control select2-search-disable"
            style={{
              border: "1px solid #00b3b6",
              borderRadius: 15,
              backgroundImage: "none",
              padding: "2px 15px 2px 15px",
              color: "#00b3b6",
            }}
            value={DefaultStatuss}
            onChange={(e) => handelChange(e)}
          >
            <option value="" style={{ color: "#4e5154", paddingTop: "10px" }}>
              Please Select
            </option>
            <option
              key={DefaultStatuss}
              value={DefaultStatuss}
              style={{ color: "#4e5154", paddingTop: "10px" }}
            >
              {DefaultStatuss}
            </option>
            <option
              key={"closed"}
              value={"closed"}
              style={{ color: "#4e5154", paddingTop: "10px" }}
            >
              Closed
            </option>
          </select>
        ) : (
          <select
            className="form-control select2-search-disable"
            style={{
              border: "1px solid #00b3b6",
              borderRadius: 15,
              backgroundImage: "none",
              padding: "2px 15px 2px 15px",
              color: "#00b3b6",
              textTransform: "capitalize",
            }}
            value={DefaultStatuss}
            onChange={(e) => handelChange(e)}
          >
            <option style={{ color: "#4e5154", paddingTop: "10px" }}>
              Select
            </option>
            {Status &&
              Status.map((el) => (
                <option
                  key={el}
                  value={el}
                  style={{ color: "#4e5154", paddingTop: "10px" }}
                >
                  {el}
                </option>
              ))}
          </select>
        )}
      </div>
    </>
  );
};

export default LeadPendingField;
