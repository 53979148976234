import requests from "./httpService";

const PolicyServices = {
  getHRPolicy(id, body) {
    return requests.get(`/hrpolicy/all?company_id=${id}`, body);
  },
  getUsersCheck(id, body) {
    return requests.get(`/hrpolicy/getUsers?company_id=${id}`, body);
  },
  createHRPolicy(body) {
    return requests.post(`/hrpolicy/create`, body);
  },
  updateHRPolicy(id,body) {
    return requests.post(`/hrpolicy/update/${id}`, body);
  },
  getPolicyDetails(id,body) {
    return requests.get(`/hrpolicy/detail/${id}`, body);
  },
};

export default PolicyServices;
