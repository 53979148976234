import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const DataParser = {
  CompanyId() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.company_id;
  },
  UserId() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.userdata
      ?.id;
  },
  userRoleTitle() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.userdata
      ?.role_title;
  },
  isHead() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.userdata
      ?.is_head;
  },
  departmentId() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.userdata
      ?.department;
  },
  CompanyIdentifier() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.company
      ?.company_identifier;
  },
  CompanyIdentifierRole() {
    const compId = JSON.parse(
      secureLocalStorage.getItem("adminInfo")
    )?.company_id;
    if (compId === 0) {
      return "admin";
    } else {
      return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.company
        ?.company_identifier;
    }
  },
  CompanyUserName() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.name;
  },

  CompanyUserImage() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.userdata
      ?.image;
  },
  UserType() {
    return JSON.parse(secureLocalStorage.getItem("adminInfo"))?.user?.userdata
      .user_type;
  },

  CompanyUserPermissions() {
    const a = secureLocalStorage.getItem("adminInfo");
    let permisssion = [];
    if (a !== undefined) {
      permisssion = JSON.parse(a)?.user?.userdata.user_permissions;
    }
    return permisssion;
  },
  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  },
  getDateinFormat() {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    const newdate = year + "-" + month + "-" + day;
    return newdate;
  },
  getDateDefaultFormat() {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    const newdate = month + "/" + day + "/" + year;
    return newdate;
  },

  getCurrentTime() {
    var time = new Date();
    return time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  },

  getDaysInMonth() {
    let days = moment().daysInMonth();
    return days;
  },

  convetTimeOld(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  },

  calculateTimeDifference(sTime, eTime) {
    var startTime = moment(sTime, "LT");
    var endTime = moment(eTime, "LT");
    var result = endTime.diff(startTime, "hours");
    return result;
  },

  calculateTimeDifference1(sTime, eTime) {
    var startTime = moment(sTime, "HH:mm a");
    var endTime = moment(eTime, "HH:mm a");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    // var resuly = hours + " hour and " + minutes + " minutes.";
    var resuly = hours + ":" + minutes;
    return resuly;
  },

  calculateTimePercentage(e, s) {
    const result = Math.round((e / s) * 100);
    return result;
  },

  convertTime(time12h) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  },
  getDaysInMonthss(year, month) {
    return new Date(year, month, 0).getDate();
  },

  calculateOvertimeAttendance(startTime, EndTime) {
    let a = moment(startTime, "HH:mm a");
    let b = moment(EndTime, "HH:mm a");
    let d = moment(b, "HH:mm").diff(moment(a, "HH:mm"));
    let e = d;

    let totalHours = e / 3600000;

    let shortTimeHours = 0;
    if (totalHours < 9) {
      shortTimeHours = 9 - totalHours;

      const decimalHours = shortTimeHours;
      const n = new Date(0, 0);
      n.setMinutes(+Math.round(decimalHours * 60));
      const days = n.getDate() - 1;
      const hours = n.getHours();
      const minutes = n.getMinutes();
      //console.log("shortTimeHours: ", `-${hours}:${minutes}`)
      return `-${hours}:${minutes}`
    }

    let overtimeHours = 0;
    if (totalHours > 9) {
      overtimeHours = totalHours - 9;

      const decimalHours = overtimeHours;
      const n = new Date(0, 0);
      n.setMinutes(+Math.round(decimalHours * 60));
      const days = n.getDate() - 1;
      const hours = n.getHours();
      const minutes = n.getMinutes();
      //console.log("Days: ", days, "Hours: ", hours, "Minutes: ", minutes);
      //console.log("shortTimeHours: ", `-${hours}:${minutes}`)
      return `${hours}:${minutes}`
      //return `"Days: ", ${days}, "Hours: ", ${hours}, "Minutes: ", ${minutes}`
    }

    //console.log("e", e);
    //console.log(`Total hours: ${totalHours}`);
    //console.log(`Short time hours: ${shortTimeHours}`);
    //console.log(`Overtime hours: ${overtimeHours}`);
  },
 
};

export default DataParser;
