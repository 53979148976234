import requests from "./httpService";

const PriorityServices = {
  getPriority(id, body) {
    return requests.get(`/priorities/all?company_id=${id}`, body);
  },

  deletePriority(id, body) {
    return requests.delete(`/priorities/${id}`, body);
  },
  createPriority(body) {
    return requests.post(`/priorities/create`, body);
  },
  editPriority(id, body) {
    return requests.post(`/priorities/update/${id}`, body);
  },
};

export default PriorityServices;
