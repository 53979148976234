import requests from "./httpService";

const CommunicationsServices = {
  getCommunicationsServices(body) {
    return requests.get(`/communication/all?company_id=${body}`, body);
  },
  deleteCommunicationsServices(id, body) {
    return requests.delete(`/communication/${id}`, body);
  },
  createCommunications(body) {
    return requests.post(`/communication/create`, body);
  },
  editCommunication(id, body) {
    return requests.post(`/communication/update/${id}`, body);
  },
};

export default CommunicationsServices;
