import React, { useState, useEffect } from "react";
import JobStatusService from "../../../Services/JobStatusService";
import Repeater from "./Repeater";
import ExpRepeater from "./ExpRepeater";
import Sidebar from "../../Layouts/Sidebar";
import DataParser from "../../../utlits/Dataparser";
import { useNavigate, useParams } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import secureLocalStorage from "react-secure-storage";
import ImageUtlits from "../../../utlits/ImageUtlits";
import ImageUtlitsUsers from "../../../utlits/ImageUtlitsUserDoc";
import axios from "axios";

const AddUsers = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const [inputFields, setInputFields] = useState([
    { degree_title: "", institue: "", end_date: "" },
  ]);

  const [ExpInputFields, setExpInputFields] = useState([
    {
      organization_title: "",
      designation: "",
      start_date: "",
      end_date: "",
      last_salary: "",
    },
  ]);

  const addExpFiled = () => {
    let newfields = {
      organization_title: "",
      designation: "",
      start_date: "",
      end_date: "",
      last_salary: "",
    };
    setExpInputFields([...ExpInputFields, newfields]);
  };

  const addFields = () => {
    let newfield = { degree_title: "", institue: "", end_date: "" };

    setInputFields([...inputFields, newfield]);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfrimPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [BusinessPhone, setBusinessPhone] = useState("");
  const [personalMobile, setPersonalMobile] = useState("");
  const [spouse, setSpouse] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [landLine, setLandline] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactPhone, setEmergenctContactPhone] = useState("");
  const [dob, setDob] = useState("");
  const [cnic, setCnic] = useState("");
  const [spouseCnic, setSpouseCnic] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [religion, setReligion] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [personPic, setPersonalPic] = useState(null);
  const [personPic2, setPersonalPic2] = useState(null);
  const [department, setDepartment] = useState("");
  const [isHead, setIsHead] = useState("");
  const [phoneExt, setPhoneExt] = useState("");
  const [relativeInWebsouls, setRealtionInWebsouls] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [dateOfjoining, setDateofJoining] = useState("");
  const [reportsTo, setReportsTo] = useState("");
  const [designation, setDesignation] = useState("");
  const [attachments, setAttachments] = useState(null);

  const [rolesList, setRolesList] = useState([]);
  const [deparmentList, setDepartmentList] = useState([]);
  const [ReportList, setReportList] = useState([]);
  const [employmentStatusList, setEmploymentStatusList] = useState([]);
  const [docList, setDocList] = useState([]);

  useEffect(() => {
    const CompanyId = DataParser.CompanyId();
    CompanyService.viewComapnyUser(id, CompanyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }

      const userDetails = res.data.user[0];
      const userAcademic = res.data.academic;
      const userExperice = res.data.experience;
      const userdoc = res.data.docss;
      setDocList(userdoc);
      setEmail(userDetails.email);
      setFirstName(userDetails.first_name);
      setLastName(userDetails.last_name);
      setRole(userDetails.role);
      setSpouse(userDetails.spouse_name);
      setBusinessPhone(userDetails.buisness_phone);
      setPersonalEmail(userDetails.personal_email);
      setPersonalMobile(userDetails.personal_mobile);
      setLandline(userDetails.personal_land_line_no);
      setCurrentAddress(userDetails.personal_current_address);
      setPermanentAddress(userDetails.personal_permanent_address);
      setNationality(userDetails.personal_nationality);
      setEmergencyContactName(userDetails.personal_emg_contact_name);
      setEmergenctContactPhone(userDetails.personal_emg_contact_phone);
      setDob(userDetails.dob || "");
      setCnic(userDetails.personal_cnic);
      setSpouseCnic(userDetails.personal_parent_cnic);
      setMaritalStatus(userDetails.personal_marital_status);
      setReligion(userDetails.personal_religion);
      setIsHead(userDetails.is_head);
      setBloodGroup(userDetails.personal_blood_group);
      setDepartment(userDetails.department);
      setPhoneExt(userDetails.phone_ext);
      setRealtionInWebsouls(userDetails.relative_websouls);
      setEmploymentStatus(userDetails.employment_status);
      setDateofJoining(userDetails.joining_date);
      setReportsTo(userDetails.report_to);
      setDesignation(userDetails.designation || "");
      setPersonalPic2(userDetails.image);
      setInputFields(userAcademic);
      setExpInputFields(userExperice);
    });
    CompanyService.getCompanyRoles(CompanyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.roles;
      setRolesList(data);
    });
    CompanyService.getCompanyDepartment(CompanyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const dataset = res.data.departments;
      setDepartmentList(dataset);
    });
    CompanyService.getCompanyReportsTo(CompanyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const dataReport = res.data.user;
      setReportList(dataReport);
    });
    JobStatusService.getJobStatusService(CompanyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const dataJob = res.data.jobstatus;
      setEmploymentStatusList(dataJob);
    });
  }, []);

  const handelSubmit = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    var formdata = new FormData();
    formdata.append("company_id", companyId);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("confirm-password", confirmPassword);
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("role", role);
    formdata.append("spouse_name", spouse);
    formdata.append("personal_email", personalEmail);
    formdata.append("personal_mobile", personalMobile);
    formdata.append("personal_land_line_no", landLine);
    formdata.append("personal_current_address", currentAddress);
    formdata.append("personal_permanent_address", permanentAddress);
    formdata.append("personal_nationality", nationality);
    formdata.append("designation", designation);
    formdata.append("personal_emg_contact_name", emergencyContactName);
    formdata.append("personal_emg_contact_phone", emergencyContactPhone);
    formdata.append("dob", dob);
    formdata.append("personal_cnic", cnic);
    formdata.append("personal_parent_cnic", spouseCnic);
    formdata.append("personal_marital_status", maritalStatus);
    formdata.append("personal_religion", religion);
    formdata.append("personal_blood_group", bloodGroup);
    formdata.append("department", department);
    formdata.append("is_head", isHead);
    formdata.append("buisness_phone", BusinessPhone);
    formdata.append("phone_ext", phoneExt);
    formdata.append("relative_websouls", relativeInWebsouls);
    formdata.append("employment_status", employmentStatus);
    formdata.append("joining_date", dateOfjoining);
    formdata.append("report_to", reportsTo);
    if (personPic) {
      formdata.append("file", personPic);
    }

    formdata.append("academic", JSON.stringify(inputFields));
    formdata.append("experience", JSON.stringify(ExpInputFields));
    const checkAuth =
      secureLocalStorage.getItem("adminInfo") === undefined
        ? ""
        : `${JSON.parse(secureLocalStorage.getItem("adminInfo")).authToken}`;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}user/update/${id}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: `${checkAuth}`,
        },
      })
      .then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
        var formData2 = new FormData();
        if (attachments) {
          for (let i = 0; i < attachments.length; i++) {
            formData2.append("files", attachments[i]);
          }
        }

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}user/userdocs/${id}`,
            formData2,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authToken: `${checkAuth}`,
              },
            }
          )
          .then((ress) => {
            navigate(`/${DataParser.CompanyIdentifierRole()}/users`);
          });
      });
  };
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 col-sm-12">
                      <form onSubmit={(e) => handelSubmit(e)}>
                        <h3>Login Info</h3>
                        <div className="row">
                          <div className="col">
                            <div className="mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="formrow-email-input"
                                placeholder="Login Email*"
                                disabled
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-3">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password*"
                                id="formrow-password-input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-3">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Confrirm Password*"
                                id="formrow-confirm-password-input"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfrimPassword(e.target.value)
                                }
                              />
                              <div className="help-block">
                                {password !== confirmPassword ? (
                                  <>
                                    <ul role="alert">
                                      <li>Must match</li>
                                    </ul>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3>User Info</h3>
                        <div className="row">
                          <div className="col">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="formrow-fname-input"
                                placeholder="First Name*"
                                // required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="formrow-lname-input"
                                placeholder="Last Name*"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                // required
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-3">
                              <select
                                className="form-select"
                                id="formrow-role-input"
                                // required
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                              >
                                <option value="">Role*</option>
                                {rolesList &&
                                  rolesList.map((el) => (
                                    <option key={el.id} value={el.id}>
                                      {el.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <h3>Personal Info</h3>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Spouse or Father’s name"
                                name="spouse_name"
                                value={spouse}
                                onChange={(e) => setSpouse(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="email"
                                className="form-control inputset"
                                placeholder="Personal Email"
                                name="personal_email"
                                value={personalEmail}
                                onChange={(e) =>
                                  setPersonalEmail(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="Personal Mobile*"
                                name="personal_mobile"
                                data-validation-required-message="Mobile no is required"
                                aria-invalid="true"
                                // required
                                value={personalMobile}
                                onChange={(e) =>
                                  setPersonalMobile(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="Land line"
                                name="personal_land_line_no"
                                value={landLine}
                                onChange={(e) => setLandline(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Current Address*"
                                name="personal_current_address"
                                data-validation-required-message="Current address is required"
                                aria-invalid="true"
                                // required
                                value={currentAddress}
                                onChange={(e) =>
                                  setCurrentAddress(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Permanent Address"
                                name="personal_permanent_address"
                                value={permanentAddress}
                                onChange={(e) =>
                                  setPermanentAddress(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Nationality*"
                                name="personal_nationality"
                                data-validation-required-message="Nationality is required"
                                aria-invalid="true"
                                // required
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Emergency Contact name"
                                name="personal_emg_contact_name"
                                value={emergencyContactName}
                                onChange={(e) =>
                                  setEmergencyContactName(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="Emergency Contact Phone"
                                name="personal_emg_contact_phone"
                                value={emergencyContactPhone}
                                onChange={(e) =>
                                  setEmergenctContactPhone(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <div className="date-picker">
                                <input
                                  type="text"
                                  name="personal_dob"
                                  className="pickadate form-control inputset"
                                  placeholder="DOB"
                                  value={dob}
                                  onChange={(e) => setDob(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="CNIC*"
                                name="personal_cnic"
                                data-validation-required-message="CNIC is required"
                                aria-invalid="true"
                                // required
                                value={cnic}
                                onChange={(e) => setCnic(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="Parent/spouse CNIC"
                                name="personal_parent_cnic"
                                value={spouseCnic}
                                onChange={(e) => setSpouseCnic(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <select
                                className="form-control "
                                id="basicSelect"
                                name="personal_marital_status"
                                value={maritalStatus}
                                onChange={(e) =>
                                  setMaritalStatus(e.target.value)
                                }
                              >
                                <option value="">Marital Status</option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Religion"
                                name="personal_religion"
                                value={religion}
                                onChange={(e) => setReligion(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Blood group"
                                name="personal_blood_group"
                                value={bloodGroup}
                                onChange={(e) => setBloodGroup(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="file"
                                className="form-control inputset"
                                placeholder="File"
                                name="File"
                                onChange={(e) =>
                                  setPersonalPic(e.target.files[0])
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="attactment_img mr-1"
                            style={{ marginRight: 5 }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_USER_IMAGE_PATH +
                                personPic2
                              }
                              className="wh-100"
                              width={100}
                              height={50}
                              alt=""
                            />
                          </div>
                        </div>
                        <h3>Offical Details</h3>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <select
                                className="form-control "
                                id="basicSelect"
                                name="department"
                                data-validation-required-message="Department is required"
                                aria-invalid="true"
                                // required
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                              >
                                <option value="">Department*</option>
                                {deparmentList &&
                                  deparmentList.map((el) => (
                                    <option key={el.id} value={el.id}>
                                      {el.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control inputset"
                                placeholder="Designation*"
                                name="designation"
                                data-validation-required-message="This title field is required"
                                aria-invalid="true"
                                // required
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <select
                                className="form-control "
                                id="basicSelect"
                                name="is_head"
                                value={isHead}
                                onChange={(e) => setIsHead(e.target.value)}
                              >
                                <option value="">Is Head?</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                              <div className="help-block" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="Buisness Phone"
                                name="buisness_phone"
                                value={BusinessPhone}
                                onChange={(e) =>
                                  setBusinessPhone(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <input
                                type="tel"
                                className="form-control inputset"
                                placeholder="Phone Ext."
                                name="phone_ext"
                                value={phoneExt}
                                onChange={(e) => setPhoneExt(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <select
                                className="form-control "
                                id="basicSelect"
                                name="relative_websouls"
                                data-validation-required-message="This field is required"
                                // required
                                value={relativeInWebsouls}
                                onChange={(e) =>
                                  setRealtionInWebsouls(e.target.value)
                                }
                              >
                                <option value="">
                                  Any Relative In webSouls?*
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <select
                                className="form-control "
                                id="basicSelect"
                                name="employment_status"
                                data-validation-required-message="Employment ststus is required"
                                value={employmentStatus}
                                onChange={(e) =>
                                  setEmploymentStatus(e.target.value)
                                }
                              >
                                <option value="">Employment Status*</option>
                                {employmentStatusList &&
                                  employmentStatusList.map((elsa) => (
                                    <option value={elsa.id}>{elsa.name}</option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <div className="date-picker">
                                <input
                                  type="date"
                                  id="issueinput3"
                                  className="form-control"
                                  name="dateopened"
                                  data-toggle="tooltip"
                                  data-trigger="hover"
                                  data-placement="top"
                                  data-title="Date Opened"
                                  data-original-title=""
                                  title=""
                                  value={dateOfjoining}
                                  onChange={(e) =>
                                    setDateofJoining(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="mb-3">
                              <select
                                className="form-control "
                                id="basicSelect"
                                name="report_to"
                                data-validation-required-message="This field is required"
                                // required
                                value={reportsTo}
                                onChange={(e) => setReportsTo(e.target.value)}
                              >
                                <option value="">Reports to*</option>
                                {ReportList &&
                                  ReportList.map((elsss) => (
                                    <option value={elsss.id}>
                                      {elsss.first_name} {elsss.last_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <h3>Academic Records</h3>
                        <div className="row">
                          <div className="repeater-default">
                            <div data-repeater-list="academic">
                              <div data-repeater-item="">
                                <Repeater
                                  inputFields={inputFields}
                                  setInputFields={setInputFields}
                                  addFields={addFields}
                                />
                              </div>
                            </div>
                            <div className="form-group overflow-hidden row">
                              <div className="col-12 mb-3">
                                <button
                                  type="button"
                                  data-repeater-create=""
                                  onClick={addFields}
                                  className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 add_new_user"
                                >
                                  <i className="icon-plus4" />
                                  <span>+</span> Add More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3>Experience</h3>
                        <div className="row">
                          <div className="repeater-default">
                            <div data-repeater-list="experience">
                              <div data-repeater-item="">
                                <ExpRepeater
                                  ExpInputFields={ExpInputFields}
                                  setExpInputFields={setExpInputFields}
                                />
                              </div>
                            </div>
                            <div className="form-group overflow-hidden row ">
                              <div className="col-12 mb-3">
                                <button
                                  type="button"
                                  onClick={addExpFiled}
                                  className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1 add_new_user"
                                >
                                  <i className="icon-plus4" />
                                  <span>+</span> Add More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3>File Attachment</h3>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <div className="form-group">
                              <div className="controls">
                                <input
                                  type="file"
                                  multiple
                                  className="form-control inputset"
                                  placeholder="Upload files"
                                  onChange={(e) =>
                                    setAttachments(e.target.files)
                                  }
                                />
                                <div className="help-block" />
                              </div>
                            </div>
                          </div>
                          {docList &&
                            docList.map((el) => (
                              <div
                                className="attactment_img mr-1"
                                style={{ marginRight: 5 }}
                              >
                                {ImageUtlitsUsers.ImageSetting(el.user_docss)}
                              </div>
                            ))}
                        </div>

                        <div className="mt-4" style={{ float: "right" }}>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUsers;
