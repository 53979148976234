import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const animatedComponents = makeAnimated();

const EditPackage = () => {
  const location = useLocation();
  const packages = location?.state?.data;

  const params = useParams();
  const id = params?.id;

  const navigate = useNavigate();

  const [modules, setModules] = useState();
  const [Title, setTitle] = useState("");
  const [Price, setPrice] = useState("");
  const [Description, setDescription] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: "tasks", label: "Tasks" },
    { value: "leads", label: "Leads" },
    { value: "projects", label: "Projects" },
  ];

  useEffect(() => {
    const packageModuless = packages?.packages_modules;
    let newArr = [];
    for (let i = 0; i < packageModuless?.length; i++) {
      newArr.push(options.find((el) => el.value === packageModuless[i]));
    }
    setModules(newArr);
    setSelectedOptions(newArr);
    setTitle(packages?.name);
    setPrice(packages?.price);
    setDescription(packages?.description);
  }, []);

  const handelMuliOPTIONS = (e) => {
    setSelectedOptions(e);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    try {
      let newStuff = [];
      for (let i = 0; i < selectedOptions.length; i++) {
        const element = selectedOptions[i].value;
        newStuff.push(element);
      }
      const EditPackageData = {
        name: Title,
        price: Price,
        description: Description,
        modules: newStuff,
      };
      CompanyService.editCompanyPackages(id, EditPackageData).then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
        if (res.Good === true) {
          navigate(`/${DataParser.CompanyIdentifierRole()}/packages`);
        }
      });
    } catch (err) {}
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Edit Package</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="#">Forms</Link>
                      </li>
                      <li className="breadcrumb-item active">Basic Elements</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      className="needs-validation"
                      noValidate
                      onSubmit={(e) => onSubmitHandler(e)}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom01"
                            >
                              Package Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="Package Title"
                              value={packages?.name}
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              Please Add a valid Title.
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom02"
                            >
                              Price
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="validationCustom02"
                              placeholder="Price"
                              value={Price}
                              onChange={(e) => setPrice(e.target.value)}
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              Please Add a valid Price.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom03"
                            >
                              Modules
                            </label>
                            <Select
                              options={options}
                              isMulti
                              value={selectedOptions}
                              onChange={(e) => handelMuliOPTIONS(e)}
                              className=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom04"
                            >
                              Description
                            </label>
                            <textarea
                              id="basicpill-address-input"
                              className="form-control"
                              rows="2"
                              value={Description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <button className="btn btn-primary" type="submit">
                        Save Changes
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPackage;
