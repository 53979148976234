import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const Settings = () => {
  const navigate = useNavigate();

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <h3 className="text mb-5">Settings</h3>
            </div>
          </div>
          <div className="row">
            {DataParser.userRoleTitle() === "HR" ||
            DataParser.userRoleTitle() === "Company Admin" ? (
              <>
                <div className="col-md-6 col-xl-3">
                  <Link
                    to={`/${DataParser.CompanyIdentifierRole()}/adminsettings`}
                  >
                    <div className="card">
                      <div className="card-body setting_tabs">
                        <i
                          style={{ color: "#000" }}
                          className="uil-cog setting_tabs_icon"
                        />
                        <h4 className="mb-1 mt-1 setting_tabs_text">
                          General Settings
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            ) : null}

            {DataParser.userRoleTitle() === "HR" ||
            DataParser.userRoleTitle() === "Company Admin" ||
            DataParser.userRoleTitle() === "Sub Admin" ? (
              <>
                <div className="col-md-6 col-xl-3">
                  <Link
                    to={`/${DataParser.CompanyIdentifierRole()}/hr_settings`}
                  >
                    <div className="card">
                      <div className="card-body setting_tabs">
                        <i
                          style={{ color: "#000" }}
                          className="uil-cog setting_tabs_icon"
                        />

                        <h4 className="mb-1 mt-1 setting_tabs_text">
                          HR Settings
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>{" "}
              </>
            ) : null}

            {/* <div className="col-md-6 col-xl-3">
              <div className="card">
                <div
                  className="card-body setting_tabs"
                  onClick={() =>
                    navigate(
                      `/${DataParser.CompanyIdentifierRole()}/leave_period`
                    )
                  }
                >
                  <i className="uil-cog setting_tabs_icon" />
                  <h4 className="mb-1 mt-1 setting_tabs_text">Leave Period</h4>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div
                  className="card-body setting_tabs"
                  onClick={() =>
                    navigate(
                      `/${DataParser.CompanyIdentifierRole()}/leave_type`
                    )
                  }
                >
                  <i className="uil-cog setting_tabs_icon" />
                  <h4 className="mb-1 mt-1 setting_tabs_text">Leave Type</h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
