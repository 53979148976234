import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataParser from "../../utlits/Dataparser";
import UserProfile from "../Reuseable/UserProfile.";
import Notofication from "../Reuseable/Notofication";
import secureLocalStorage from "react-secure-storage";

const Header = (props) => {
  const { handleChange } = props;
  const [userPermissions, setuserPermissions] = useState([]);
  const [compId, setcompId] = useState("");

  useEffect(() => {
    const companyid = DataParser.CompanyId();
    setcompId(companyid);
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const navigate = useNavigate();
  const username = DataParser.CompanyUserName();
  const userImage = DataParser.CompanyUserImage();

  const handelLogout = (e) => {
    e.preventDefault();
    secureLocalStorage.clear();
    navigate("/");
  };

  const handelEditItem = (e) => {
    e.preventDefault();
    const id = DataParser.UserId();
    navigate(`/${DataParser.CompanyIdentifierRole()}/users/${id}`);
  };

  const goToLeads = (e) => {
    e.preventDefault();

    const companyIdetifier = DataParser.CompanyIdentifier();
    navigate(`/${companyIdetifier}/leads`, {
      state: {
        isLeads: true,
      },
    });
  };

  const goToProjects = (e) => {
    e.preventDefault();

    const companyIdetifier = DataParser.CompanyIdentifier();
    navigate(`/${companyIdetifier}/projects`, {
      state: {
        isProjects: true,
      },
    });
  };

  const goToTasks = (e) => {
    e.preventDefault();
    const companyIdetifier = DataParser.CompanyIdentifier();
    navigate(`/${companyIdetifier}/tasks`, {
      state: {
        isTasks: true,
      },
    });
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* LOGO */}
            <div className="navbar-brand-box">
              {DataParser.CompanyId() === 0 ? (
                <>
                  {" "}
                  <Link
                    to={`/${DataParser.CompanyIdentifierRole()}/dashboard`}
                    className="logo logo-dark"
                  >
                    <span className="logo-sm">
                      <img
                        src="/assets/images/logo-sm.png"
                        alt=""
                        height={22}
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        src="/assets/images/logo-dark.png"
                        alt=""
                        height={20}
                      />
                    </span>
                  </Link>
                  <Link
                    to={`/${DataParser.CompanyIdentifierRole()}/dashboard`}
                    className="logo logo-light"
                  >
                    <span className="logo-sm">
                      <img
                        src="/assets/images/logo-sm.png"
                        alt=""
                        height={22}
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        src="/assets/images/logo-light.png"
                        alt=""
                        height={20}
                      />
                    </span>
                  </Link>{" "}
                </>
              ) : (
                <>
                  <Link
                    to={`/${DataParser.CompanyIdentifierRole()}/home`}
                    className="logo logo-dark"
                  >
                    <span className="logo-sm">
                      <img
                        src="/assets/images/logo-sm.png"
                        alt=""
                        height={22}
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        src="/assets/images/logo-dark.png"
                        alt=""
                        height={20}
                      />
                    </span>
                  </Link>
                  <Link
                    to={`/${DataParser.CompanyIdentifierRole()}/home`}
                    className="logo logo-light"
                  >
                    <span className="logo-sm">
                      <img
                        src="/assets/images/logo-sm.png"
                        alt=""
                        height={22}
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        src="/assets/images/logo-light.png"
                        alt=""
                        height={20}
                      />
                    </span>
                  </Link>
                </>
              )}
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              onClick={handleChange}
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="uil-search" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {compId > 0 ? (
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="uil-plus-circle" />
                </button>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end w-100">
                  {userPermissions.indexOf("leads-create") !== -1 ? (
                    <div className="row g-0">
                      <div className="col">
                        <Link
                          className="dropdown-item"
                          onClick={(e) => goToLeads(e)}
                          to="#"
                        >
                          <i className="uil-arrow-growth" /> &nbsp;
                          <span>Leads</span>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {userPermissions.indexOf("tasks-create") !== -1 ? (
                    <div className="row g-0">
                      <div className="col">
                        <Link
                          className="dropdown-item"
                          onClick={(e) => goToTasks(e)}
                          to="#"
                        >
                          <i className="uil-list-ul" /> &nbsp;
                          <span>Tasks</span>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {/* {userPermissions.indexOf("projects-create") !== -1 ? (
                    <div className="row g-0">
                      <div className="col">
                        <Link
                          className="dropdown-item"
                          onClick={(e) => goToProjects(e)}
                          to="#"
                        >
                          <i className="uil-paperclip" /> &nbsp;
                          <span>Projects</span>
                        </Link>
                      </div>
                    </div>
                  ) : null} */}
                </div>
              </div>
            ) : null}

            <Notofication />

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <UserProfile username={username} userImage={userImage} />

                <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
                  {username}
                </span>
                <i className="uil-angle-down d-none d-xl-inline-block font-size-15" />
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={(e) => handelEditItem(e)}
                >
                  <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1" />
                  <span className="align-middle">Edit Profile</span>
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={(e) => handelLogout(e)}
                  to="#"
                >
                  <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted" />
                  <span className="align-middle">Sign out</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
