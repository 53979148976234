import requests from "./httpService";

const LeaveSessionService = {
  getLeaveSeassions(id, body) {
    return requests.get(`/leavesession/all?company_id=${id}`, body);
  },
  createLeaveSeasions(body) {
    return requests.post(`/leavesession/create`, body);
  },
  updateLeaveSeasions(id, body) {
    return requests.post(`/leavesession/update/${id}`, body);
  },
  statusUpdateLeaveSeasions(id, comid, body) {
    return requests.post(
      `/leavesession/statusupdate/${id}?company_id=${comid}`,
      body
    );
  },
  deleteLeaveSeassion(id, body) {
    return requests.delete(`/leavesession/${id}`, body);
  },
};

export default LeaveSessionService;
