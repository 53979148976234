import { DatePicker, Popconfirm } from "antd";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import CompanyService from "../../../Services/CompanyService";
import LeadsServices from "../../../Services/LeadsServices";
import DataParser from "../../../utlits/Dataparser";
import ImageUtlits from "../../../utlits/ImageUtlits";
import UserProfile from "../../Reuseable/UserProfile.";
import shortid from "shortid";

const EditLeads = (props) => {
  const {
    LeadNotes,
    offcanvasid,
    editID,
    labelledby,
    title,
    leadOwner,
    leadSource,
    leadDueDate,
    leadDepartmentId,
    leadPackage,
    contactName,
    leadSatus,
    leadCumincation,
    details,
    owner,
    setTitle,
    setLeadOwner,
    setLeadSource,
    setLeadDueDate,
    setleadDepartmentId,
    setLeadPackage,
    setContactName,
    setLeadStatus,
    setLeadComunications,
    setDetails,
    setOwner,
    leadsOwner,
    leadsSource,
    leadsDepartments,
    leadsCustomers,
    leadsStatus,
    leadsComunication,
    LeadViewAttachment,
    setLeadViewAttachment,
    UpdateNotesAttac,
    setUpdateNotesAttac,
    LeadeViewData,
    setLeadeViewData,
    LeadNotification,
    setLeadNotification,
    LeadNote,
    setLeadNote,
    LeadNotesAttachmentImages,
    userPermissions,
    reFreshData,
    updateLead,
    editLoading,
    setEditLoading,
    dateLoader,
    setDateLoader,
    LeadAttachmentImages,
    LeadNotifications,
  } = props;

  const companyId = DataParser.CompanyId();
  const companyIdentifier = DataParser.CompanyIdentifier();

  const [updatingNotes, setUpdatingNotes] = useState(false);
  const [noteEdit, setNoteEdit] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const [UpdatedNote, setUpdatedNote] = useState("");
  const [leadCatrgotyPackList, setLeadCategoryPackList] = useState([]);

  const [fileForLeadNote, setFileForLeadNote] = useState(null);
  const [commentForLeadNote, setCommentForLeadNote] = useState(null);

  const [leadAttachments, setLeadAttachments] = useState(null);

  const [customerViewName, setCustomerViewName] = useState("");
  const [customerViewEmail, setCustomerViewEmail] = useState("");
  const [customerViewPhone, setCustomerViewPhone] = useState("");
  const [customerViewDesgination, setCustomerViewDesignation] = useState("");
  const [customerViewCompany, setCustomerViewCompany] = useState("");

  const enUrl = process.env.REACT_APP_NOTES_PATH;

  const editBlockId = (e, id) => {
    e.preventDefault();
    setEditNoteId(id);
    setNoteEdit(!noteEdit);
  };

  setTimeout(() => {
    setEditLoading(false);
  }, 2000);

  const leadNoteDeleteFunction = (e, id) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
    };
    LeadsServices.leadNoteDelete(id, body).then((res) => {
      setUpdatingNotes(!updatingNotes);
      LeadNotes(editID);
    });
  };

  const onDateChange = (date) => {
    setLeadDueDate(date);
  };

  const pinToTop = (e, id) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
    };
    LeadsServices.pinToTop(id, body).then((res) => {
      setUpdatingNotes(!updatingNotes);
      LeadNotes(editID);
    });
  };

  const noteUpdated = (e, id) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      notes: UpdatedNote,
    };
    LeadsServices.noteUpdate(id, body).then((res) => {
      setUpdatingNotes(!updatingNotes);
      LeadNotes(editID);
    });
    setNoteEdit(false);
  };

  const AddLeadNote = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("file", fileForLeadNote);
    data.append("notes", commentForLeadNote);
    data.append("company_id", companyId);
    data.append("company_identifier", companyIdentifier);

    const checkAuth =
      secureLocalStorage.getItem("adminInfo") === undefined
        ? ""
        : `${JSON.parse(secureLocalStorage.getItem("adminInfo")).authToken}`;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}leads/addnote/${editID}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: `${checkAuth}`,
        },
      })
      .then((res) => {
        LeadNotes(editID);
        LeadNotesAttachmentImages(editID);
        LeadNotifications(editID);
        setCommentForLeadNote("");
        setFileForLeadNote("");
      })
      .catch((err) => console.log("Failed", err.message));
  };

  const handelViewCustomer = (e, id) => {
    e.preventDefault();
    CompanyService.getCompanyCustomerDetail(id).then((res) => {
      const companyCustomer = res.data.customer[0];
      setCustomerViewName(companyCustomer.name);
      setCustomerViewEmail(companyCustomer.email);
      setCustomerViewPhone(companyCustomer.mobile);
      setCustomerViewDesignation(companyCustomer.designation);
      setCustomerViewCompany(companyCustomer.company);
    });
  };

  const confirm = (e) => {
    const data = {
      company_id: companyId,
      company_identifier: companyIdentifier,
    };

    LeadsServices.deleteLead(editID, data).then((res) => {
      if (res.Good) {
        reFreshData(e);
        var link = document.getElementById("drawerClose");
        link.click();
      }
    });
  };

  const addAttachment = (e) => {
    var formdata = new FormData();
    formdata.append("file", leadAttachments);
    formdata.append("company_id", companyId);
    formdata.append("company_identifier", companyIdentifier);

    const checkAuth =
      secureLocalStorage.getItem("adminInfo") === undefined
        ? ""
        : `${JSON.parse(secureLocalStorage.getItem("adminInfo")).authToken}`;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}leads/attachments/${editID}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authToken: `${checkAuth}`,
          },
        }
      )
      .then((res) => {
        LeadAttachmentImages(editID);
      });
  };

  const getLeadCategories = () => {
    if (leadDepartmentId !== null)
      LeadsServices.viewLeadCategoryPackage(leadDepartmentId, companyId).then(
        (res) => {
          const data = res.data.category_packages;
          setLeadCategoryPackList(data);
        }
      );
  };

  useEffect(() => {
    getLeadCategories();
    addAttachment();
  }, [leadAttachments]);

  // const setNewAtta = (e) => {};

  // const updateLead = (e) => {
  //   e.preventDefault();
  //   const leadUpdate = {
  //     company_id: companyId,
  //     company_identifier: companyIdentifier,
  //     title: title,
  //     lead_owner: leadOwner,
  //     lead_source: leadSource,
  //     due_date: leadDueDate,
  //     department_id: leadDepartmentId,
  //     lead_package: leadPackage,
  //     contact_name: contactName,
  //     lead_status: leadSatus,
  //     com_source: leadCumincation,
  //     details: details,
  //   };
  //   LeadsServices.updateLead(editID, leadUpdate).then((res) => {
  //     if (res.Good) {
  //       reFreshData(e);
  //     }
  //   });
  // };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        style={{ width: "650px" }}
        tabIndex={-1}
        id={offcanvasid}
        aria-labelledby={labelledby}
      >
        <div
          className="offcanvas-header border-bottom w-100"
          style={{ padding: "10px 10px 10px 10px" }}
        >
          <div className="col-md-9 d-flex">
            <h5 style={{ margin: "0px" }} id={labelledby}>
              Edit Leads
            </h5>
          </div>
          <div className="col-md-3">
            <ul className="headerClose">
              <li style={{ paddingRight: "6px" }}>
                <label style={{ margin: "0px" }} htmlFor="file-input">
                  <i
                    style={{
                      color: "#6f7782",
                      cursor: "pointer",
                      fontSize: "19px",
                      paddingLeft: "3px",
                    }}
                    className="uil-paperclip"
                  />
                </label>

                <input
                  id="file-input"
                  onChange={(e) => setLeadAttachments(e.target.files[0])}
                  style={{ display: "none" }}
                  type="file"
                />
                <span type="button" className="text-reset pr-5"></span>
              </li>
              {userPermissions.indexOf("leads-delete") !== -1 ? (
                <li style={{ paddingRight: "10px" }}>
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure to delete this Lead?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                    style={{ "z-index": 1060 }}
                  >
                    <span type="button" className="text-reset pr-5">
                      <i
                        style={{
                          color: "#6f7782",
                          cursor: "pointer",
                          fontSize: "19px",
                        }}
                        className="uil-trash"
                      />
                    </span>
                  </Popconfirm>
                </li>
              ) : null}
              <li>
                <button
                  type="button"
                  id="drawerClose"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </li>
            </ul>
          </div>
        </div>

        {editLoading ? (
          <>
            <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
              <div className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <div className="controls row">
                      <div className="col-sm-12 title_font_size">
                        <Skeleton
                          style={{
                            width: "95%",
                            paddingTop: "10px",
                            marginLeft: "1rem",
                            paddingBottom: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="form-group  mt-2">
                    <div className="controls row">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row  mt-2">
                    <div className="col-sm-3 pl-2">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Skeleton
                        style={{
                          width: "95%",
                          marginLeft: "1rem",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="offcanvas-body">
              {/* FIELDS */}

              <form onSubmit={(e) => updateLead(e, editID)}>
                <div
                  style={{ paddingRight: "2rem" }}
                  className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy"
                >
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <div className="controls row">
                          <div className="col-sm-12 title_font_size">
                            <input
                              type="text"
                              id="titleid"
                              className="form-control w-100 inputset custominputTitle canvous-title-field"
                              placeholder="Title"
                              style={{ border: "0px" }}
                              name="title"
                              required
                              data-validation-required-message="This role field is required"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect"
                          >
                            Assignee<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="form-control selectset custominput"
                            style={{ border: "0px" }}
                            name="lead_owner"
                            id="basicSelect"
                            required
                            onChange={(e) => setLeadOwner(e.target.value)}
                            value={leadOwner}
                          >
                            <option value="">Please Select</option>
                            {leadsOwner &&
                              leadsOwner.map((el) => (
                                <option key={shortid.generate()} value={el.id}>
                                  {leadOwner === el.id
                                    ? "Assign me"
                                    : el.first_name + " " + el.last_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect2"
                          >
                            Source<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="form-control selectset custominput"
                            name="lead_source"
                            id="basicSelect2"
                            required
                            value={leadSource}
                            onChange={(e) => setLeadSource(e.target.value)}
                            style={{ border: "0px" }}
                          >
                            {leadsSource &&
                              leadsSource.map((el, index) => (
                                <option key={shortid.generate()} value={el}>
                                  {el}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group  mt-2">
                        <div className="controls row">
                          <div className="col-sm-3 pl-2">
                            <label
                              className="offcanvas_labels"
                              htmlFor="basicSelect6"
                            >
                              Due Date
                            </label>
                          </div>
                          <div className="col-sm-4">
                            <DatePicker
                              disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current < moment(customDate, "YYYY-MM-DD")
                                );
                              }}
                              allowClear={false}
                              bordered={false}
                              value={moment(leadDueDate)}
                              onChange={onDateChange}
                              format={"DD-MM-YYYY"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row  mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect3"
                          >
                            Lead Category<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="dept form-control selectset custominput"
                            name="department_id"
                            id="basicSelect3"
                            required
                            style={{ border: "0px" }}
                            value={leadDepartmentId}
                            onChange={(e) =>
                              setleadDepartmentId(e.target.value)
                            }
                          >
                            <option value="">Select Option</option>
                            {leadsDepartments &&
                              leadsDepartments.map((el, index) => (
                                <option key={shortid.generate()} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row  mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect3"
                          >
                            Category Package
                            <span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="dept form-control selectset custominput"
                            name="department_id"
                            id="basicSelect3"
                            required
                            style={{ border: "0px" }}
                            value={leadPackage}
                            onChange={(e) => setLeadPackage(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {leadCatrgotyPackList &&
                              leadCatrgotyPackList.map((els) => (
                                <option key={shortid.generate()} value={els.id}>
                                  {els.lead_package}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect5"
                          >
                            Customer<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4 d-flex" id="cust">
                          <select
                            className="form-control select2 select2-hidden-accessible selectset custominput"
                            name="contact_name"
                            id="basicSelect5"
                            required
                            data-select2-id="basicSelect5"
                            tabIndex={-1}
                            aria-hidden="true"
                            style={{ border: "0px" }}
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {leadsCustomers &&
                              leadsCustomers.map((el, index) => (
                                <option key={shortid.generate()} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-sm-5 d-flex" id="cust">
                          <span
                            style={{ cursor: "pointer", placeSelf: "center" }}
                            data-bs-toggle="modal"
                            data-bs-target="#myModalViewCustomer"
                            onClick={(e) => handelViewCustomer(e, contactName)}
                          >
                            <i className="uil-eye"></i> View Customer
                          </span>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect7"
                          >
                            Lead Status<span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="form-control selectset custominput"
                            name="lead_status"
                            id="basicSelect7"
                            value={leadSatus}
                            onChange={(e) => setLeadStatus(e.target.value)}
                            required
                            style={{
                              textTransform: "capitalize",
                              border: "0px",
                            }}
                          >
                            <option value="">Select Option</option>
                            {leadsStatus &&
                              leadsStatus.map((el) => (
                                <option key={shortid.generate()} value={el}>
                                  {el}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect7"
                          >
                            Communication Source
                            <span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="form-control selectset custominput"
                            name="com_source"
                            id="basicSelect7"
                            required
                            value={leadCumincation}
                            onChange={(e) =>
                              setLeadComunications(e.target.value)
                            }
                            style={{ border: "0px" }}
                          >
                            <option value="">Select Option</option>
                            {leadsComunication &&
                              leadsComunication.map((el) => (
                                <option key={shortid.generate()} value={el.id}>
                                  {el.value}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <div className="controls row">
                          <div className="col-sm-3 pl-2">
                            <label
                              className="offcanvas_labels"
                              htmlFor="basicSelect8"
                            >
                              Details
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <textarea
                              className="form-control w-100 autosize textset canvous-textarea-field-edit"
                              rows={3}
                              placeholder="Details"
                              id="basicSelect8"
                              name="details"
                              data-validation-required-message="This role field is required"
                              data-gramm="false"
                              wt-ignore-input="true"
                              value={details}
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4 mb-4"
                      style={{ paddingRight: "30px" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                      >
                        Submit
                      </button>
                    </div>
                    {/* Modal */}
                  </div>
                </div>
                <div
                  id="myModalViewCustomer"
                  className="modal fade"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myModalLabel">
                          Add New Customer
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <form>
                        <div className="modal-body">
                          <input
                            type="text"
                            id="customer-name"
                            className="form-control mb-3"
                            placeholder="Customer Name*"
                            required
                            value={customerViewName}
                          />

                          <input
                            type="tel"
                            id="customer-phone"
                            className="form-control mb-3"
                            placeholder="Customer Phone Number*"
                            required
                            value={customerViewPhone}
                          />
                          <input
                            type="email"
                            id="customer-email"
                            className="form-control mb-3"
                            placeholder="Customer Email"
                            value={customerViewEmail}
                          />
                          <input
                            type="text"
                            id="customer-designaton"
                            className="form-control mb-3"
                            placeholder="Customer designaton"
                            value={customerViewDesgination}
                          />
                          <input
                            type="text"
                            id="customer-company"
                            className="form-control mb-3"
                            placeholder="Customer Company"
                            value={customerViewCompany}
                          />
                        </div>
                      </form>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
              </form>

              {/* LEAD ATTACHMENTS */}
              {LeadViewAttachment && LeadViewAttachment.length === 0 ? null : (
                <span style={{ color: "#404e67" }} id="noteattachments">
                  <h4 style={{ fontWeight: 500 }}>Leads Attachments</h4>
                  {LeadViewAttachment &&
                    LeadViewAttachment.map((item) => (
                      <a
                        target="_blank"
                        href={enUrl + item.attachment}
                        rel="noreferrer"
                        download
                        key={shortid.generate()}
                      >
                        <div className="uploaded_attachments">
                          <div
                            className="attactment_img mr-1"
                            style={{ marginRight: "5px" }}
                          >
                            {ImageUtlits.leadImageSetting(item.attachment)}
                          </div>
                          {/* <span className="tooltip_text">{item}</span> */}
                        </div>
                      </a>
                    ))}
                </span>
              )}

              {/* LEAD Note's ATTACHMENTS */}
              {UpdateNotesAttac && UpdateNotesAttac.length === 0 ? null : (
                <span style={{ color: "#404e67" }} id="noteattachments">
                  <h4 style={{ fontWeight: 500 }}>Notes Attachments</h4>
                  {UpdateNotesAttac &&
                    UpdateNotesAttac.map((item) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={enUrl + item}
                        download
                        key={shortid.generate()}
                      >
                        <div className="uploaded_attachments">
                          <div
                            className="attactment_img mr-1"
                            style={{ marginRight: "5px" }}
                          >
                            {ImageUtlits.leadImageSetting(item)}
                          </div>
                          {/* <span className="tooltip_text">{item}</span> */}
                        </div>
                      </a>
                    ))}
                </span>
              )}

              {/* LEad NOtification */}
              <div className="task_story_feed">
                <div className="notes_detail subtask_outlook">
                  <div className="assignee_thumbnail">
                    <UserProfile
                      username={`${owner?.first_name} ${owner?.last_name}`}
                      userImage={owner?.image}
                    />
                  </div>
                  <div className="verticaly_centered_text">
                    <div className="assignee_title">
                      <p>
                        {owner?.first_name}&nbsp;{owner?.last_name} Created this
                        lead&nbsp;
                        <span>
                          {moment(LeadeViewData?.created_at)
                            .startOf()
                            .fromNow()}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {LeadNotification && LeadNotification.length === 0 ? null : (
                  <>
                    {LeadNotification &&
                      LeadNotification.map((item) => (
                        <div
                          className="subtask_detail_manage"
                          key={shortid.generate()}
                        >
                          <div className="assignee_title d-flex">
                            <Link to="#">{item.created_by.first_name}</Link>
                            <p>
                              {item.title}&nbsp;
                              <span>
                                {moment(item?.created_at).startOf().fromNow()}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>

              {/* LeadNote */}
              {LeadNote && LeadNote.length === 0 ? null : (
                <>
                  {LeadNote &&
                    LeadNote?.map((item, index) => (
                      <div id="notes" key={index}>
                        <div
                          style={{ paddingRight: "5px" }}
                          className={
                            item.pin_top === 1
                              ? `notes_detail pintotpcolor`
                              : "notes_detail"
                          }
                        >
                          <div className="assignee_thumbnail">
                            <UserProfile
                              username={item?.user_name}
                              userImage={item?.user_image}
                            />
                          </div>
                          <div className="verticaly_centered_text">
                            <div className="assignee_title">
                              <p>
                                {item.user_name}{" "}
                                <span>
                                  {moment(item?.created_at).startOf().fromNow()}
                                  {/* {DataParser.getNumberOfDays(
                                        item.created_at,
                                        currentDate
                                      )}{" "}
                                      day ago */}
                                </span>
                              </p>
                            </div>
                            <div className="assignee_description">
                              <p
                                style={
                                  noteEdit && item.id === editNoteId
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                                id="p-21"
                              >
                                {item.notes}
                              </p>

                              {item.attachments &&
                              item.attachments.length > 0 ? (
                                <>
                                  {item.attachments.map((el) => (
                                    <a
                                      target="_blank"
                                      href={enUrl + el}
                                      download
                                      key={el}
                                    >
                                      <div className="uploaded_attachments mb-0">
                                        <div className="uploaded_img attactment_img">
                                          {ImageUtlits.leadImageSetting(el)}
                                          Download
                                        </div>
                                        {/* <span className="tooltip_text">
                                                  {el}
                                                </span> */}
                                      </div>
                                    </a>
                                  ))}
                                </>
                              ) : null}
                              <textarea
                                style={
                                  noteEdit && item.id === editNoteId
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                                className="form-control w-100 autosize textset canvous-textarea-field-edit"
                                name="edit_notes"
                                id="txt-21"
                                data-note-id={21}
                                defaultValue={item.notes}
                                onMouseOut={(e) => noteUpdated(e, item.id)}
                                onChange={(e) => setUpdatedNote(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="like_icon dropdonw">
                            <span
                              className="dropdown "
                              style={{ paddingLeft: 5 }}
                            >
                              <div className="btn-group mt-2 me-1 dropstart">
                                <span
                                  className="waves-effect waves-light dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="uil-ellipsis-v" />
                                </span>
                                <div
                                  className="dropdown-menu transformsetsssss"
                                  style={{}}
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={(e) => pinToTop(e, item.id)}
                                  >
                                    {item.pin_top !== 1
                                      ? "Pin to top"
                                      : "Unpin"}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={(e) => editBlockId(e, item.id)}
                                  >
                                    Edit Note
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={(e) =>
                                      leadNoteDeleteFunction(e, item.id)
                                    }
                                  >
                                    Delete Note
                                  </Link>
                                </div>
                              </div>

                              <Link
                                className=""
                                id="todo-sidebar-dropdown"
                                // data-toggle="dropdown"
                                // aria-haspopup="true"
                                // aria-expanded="true"
                                to="#"
                              ></Link>
                            </span>
                          </div>

                          <div>
                            <div className="like_icon dropdonw">
                              {item.pin_top === 1 ? (
                                <i className="fa fa-thumb-tack"></i>
                              ) : null}
                              <span
                                style={{ paddingLeft: "5px" }}
                                className="dropdown "
                              >
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  id="todo-sidebar-dropdown"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <i className="feather icon-more-vertical" />
                                </Link>
                                <span
                                  className="dropdown-menu dropdown-menu-right"
                                  aria-labelledby="todo-sidebar-dropdown"
                                >
                                  {item.pin_top !== 1 ? (
                                    <Link
                                      to="#"
                                      className="dropdown-item pin"
                                      data-note-id={34}
                                      onClick={(e) => pinToTop(e, item.id)}
                                    >
                                      Pin to top
                                    </Link>
                                  ) : (
                                    <Link
                                      to="#"
                                      className="dropdown-item pin"
                                      data-note-id={34}
                                      onClick={(e) => pinToTop(e, item.id)}
                                    >
                                      Unpin
                                    </Link>
                                  )}

                                  <Link
                                    to="#"
                                    className="dropdown-item ed"
                                    data-note-id={34}
                                    onClick={(e) => editBlockId(e, item.id)}
                                  >
                                    Edit Note
                                  </Link>
                                  <Link
                                    to="#"
                                    className="dropdown-item del"
                                    data-note-id={34}
                                    onClick={(e) =>
                                      leadNoteDeleteFunction(e, item.id)
                                    }
                                  >
                                    Delete Note
                                  </Link>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

            <div className="offcanvas-footer">
              <form
                enctype="multipart/form-data"
                onSubmit={(e) => AddLeadNote(e)}
              >
                <div className="dropdown dropdown-user nav-item">
                  <div className="image-upload">
                    <div className="row">
                      <div className="col-md-12 " style={{ display: "flex" }}>
                        <h6 className="offcanvas-footer-heading">
                          Add an attachment with notes
                        </h6>
                        <label htmlFor="file-input-notes">
                          <i
                            style={{
                              color: "#6f7782",
                              cursor: "pointer",
                            }}
                            className="uil-paperclip"
                          />
                        </label>
                        <input
                          id="file-input-notes"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setFileForLeadNote(e.target.files[0])
                          }
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  className="form-control  notess textset"
                  placeholder="Notes"
                  name="notes"
                  rows={3}
                  required
                  data-validation-required-message="This role field is required"
                  data-gramm="false"
                  wt-ignore-input="true"
                  style={{ borderColor: "rgb(228, 231, 237)" }}
                  value={commentForLeadNote}
                  onChange={(e) => setCommentForLeadNote(e.target.value)}
                />
                <div className="mt-4">
                  <div className="float-end">
                    <button type="submit" className="btn btn-primary glow">
                      Add Note
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditLeads;
