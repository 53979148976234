import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import CompanyService from "../../../Services/CompanyService";
import Sidebar from "../../Layouts/Sidebar";
import { Helmet } from "react-helmet";
import StatusBadge from "../../Reuseable/StatusBadge";
import secureLocalStorage from "react-secure-storage";

const Companies = () => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const navigate = useNavigate();

  const getData = () => {
    CompanyService.getAllComapny().then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.companies;
      setAllCompanies(data);
    });
  };

  useEffect(() => {
    getData();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const handelEditItem = (e, item) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/companies/edit`, {
      state: {
        data: item,
      },
    });
  };

  const handelDeleteItem = (e, item) => {
    e.preventDefault();
    const id = item.id;
    const newData = allCompanies.filter((el) => el.id !== id);
    setAllCompanies(newData);
    CompanyService.deleteCompany(id)
      .then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Helmet>
        <title>Companies - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Companies</h4>
                  <div className="page-title-right">
                    {userPermissions.indexOf("companies-create") !== -1 ? (
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/companies/create`}
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Create Company
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Comany</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allCompanies &&
                            allCompanies.map((item, index) => (
                              <tr key={item.id}>
                                <th scope="row">{index + 1}</th>
                                <td className="tableset">{item.company_name}</td>
                                <td className="tableset">
                                  {item.email}
                                  <br />
                                  {item.company_identifier}
                                </td>
                                <td className="tableset">{item.phone}</td>
                                <td className="tableset">{item.address}</td>
                                <td className="tableset">
                                  {userPermissions.indexOf("companies-edit") !==
                                  -1 ? (
                                    <StatusBadge
                                      item={item}
                                      api={CompanyService.updateCompanyStatus}
                                    />
                                  ) : (
                                    <>
                                      {item.status === 1 ? (
                                        <span className="btn btn-success waves-effect waves-light btn-sm">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="btn btn-danger waves-effect waves-light btn-sm">
                                          Deactive
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className="tableset">
                                  {userPermissions.indexOf(
                                    "companies-delete"
                                  ) !== -1 ? (
                                    <Link
                                      to="#"
                                      className="cursor-pointer"
                                      onClick={(e) => handelDeleteItem(e, item)}
                                    >
                                      <i className="uil-trash iconscolor" />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  {userPermissions.indexOf("companies-edit") !==
                                  -1 ? (
                                    <Link
                                      onClick={(e) => handelEditItem(e, item)}
                                      className="cursor-pointer"
                                    >
                                      <i className="uil-pen iconscolor" />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companies;
