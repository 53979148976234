import moment from "moment";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import WorkFlowsService from "../../../Services/WorkFlowsService";
import secureLocalStorage from "react-secure-storage";

const Workflow = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [workflowsList, setworkflowsList] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);

  const [categoryName, setCategoryName] = useState("");
  const [categoryEditName, setCategoryEditName] = useState("");

  const [editId, setEditId] = useState("");

  const CompanyId = DataParser.CompanyId();

  const getData = () => {
    setLoading(true);
    WorkFlowsService.getWorkflows(CompanyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const dataCategory = res.data.workflows;
      setworkflowsList(dataCategory);
      const upermission = DataParser.CompanyUserPermissions();
      setuserPermissions(upermission);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handelDelete = (e, id) => {
    e.preventDefault();
    const companyID = DataParser.CompanyId();
    const newData = workflowsList.filter((el) => el.id !== id);
    setworkflowsList(newData);
    WorkFlowsService.deleteWorkflows(id, companyID).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const data = { company_id: companyId, name: categoryName };
    WorkFlowsService.createWorkflows(data).then((res) => {
      getData();
      var link = document.getElementById("workFlowModalCreate");
      link.click();
      setCategoryName("");
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      if (res.Good) {
        navigate(`/${DataParser.CompanyIdentifierRole()}}/workflow`);
      }
    });
  };

  const handleOpenUpadte = (e, id) => {
    setEditId(id);
    WorkFlowsService.showWorkflows(id).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.workflow.name;
      setCategoryEditName(data);
    });
  };

  const onEditSubmit = (e) => {
    e.preventDefault();

    const companyId = DataParser.CompanyId();
    const data = { company_id: companyId, name: categoryEditName };
    WorkFlowsService.updateWorkflows(editId, data).then((res) => {
      var link = document.getElementById("workFlowModalEdit");
      link.click();
      getData();
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Workflow - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Workflows</h4>
                  <div className="page-title-right">
                    <>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#createWorkFlowModal"
                      >
                        Create WorkFlow
                      </button>
                      {/* Create modal content */}
                      <div
                        id="createWorkFlowModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create WorkFlow
                              </h5>
                              <button
                                type="button"
                                id="workFlowModalCreate"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <form>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Name
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Title"
                                        required
                                        value={categoryName}
                                        onChange={(e) =>
                                          setCategoryName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  id="closeEdit"
                                  className="btn btn-light waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  onClick={(e) => handelSubmit(e)}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Name</th>
                            <th>Creation Date</th>
                            <th>Tasks</th>
                            <th>Action</th>
                          </tr>
                          <tbody>
                            {isLoading ? (
                              <>
                                <tr>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                  <td>
                                    <Skeleton width="40%" />
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                {workflowsList &&
                                  workflowsList.map((cat, index) => (
                                    <tr key={index}>
                                      <td className="tableset">{index + 1}</td>
                                      <td className="tableset">{cat.name}</td>

                                      <td className="tableset">
                                        {moment(cat.created_at).format(
                                          "D/MM/YYYY"
                                        )}
                                      </td>
                                      <td className="tableset">
                                        {userPermissions.indexOf(
                                          "work-flow-create"
                                        ) !== -1 ? (
                                          <Link
                                            to={`/${DataParser.CompanyIdentifierRole()}/workflow/tasks/${
                                              cat.id
                                            }`}
                                          >
                                            <span className="btn btn-success waves-effect waves-light btn-sm">
                                              View Tasks
                                            </span>
                                          </Link>
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td className="tableset">
                                        <div className="invoice-action">
                                          {userPermissions.indexOf(
                                            "work-flow-delete"
                                          ) !== -1 ? (
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                handelDelete(e, cat.id)
                                              }
                                              className="invoice-action-view mr-1"
                                            >
                                              <i className="uil-trash iconscolor" />
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                          {userPermissions.indexOf(
                                            "work-flow-edit"
                                          ) !== -1 ? (
                                            <Link
                                              to="#"
                                              onClick={(e) =>
                                                handleOpenUpadte(e, cat.id)
                                              }
                                              data-bs-toggle="modal"
                                              data-bs-target="#editWorkFlowModal"
                                            >
                                              <i className="uil-pen iconscolor" />
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </>
                            )}
                          </tbody>
                        </thead>
                      </table>
                      <div
                        id="editWorkFlowModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Edit WorkFlow
                              </h5>
                              <button
                                type="button"
                                id="workFlowModalEdit"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <form>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Name
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Title"
                                        required
                                        value={categoryEditName}
                                        onChange={(e) =>
                                          setCategoryEditName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  onClick={(e) => onEditSubmit(e)}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Workflow;
