import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import shortid from "shortid";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import LeaveBadge from "../../Reuseable/LeaveBadge";

const UserLeaveEmployee = () => {
  const location = useLocation();
  const userid = DataParser.UserId();
  const compid = DataParser.CompanyId();

  const [userLeaves, setUserLeaves] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state !== null) {
      const stateData = location?.state?.data;
      getUserLeaves(stateData, compid);
    } else {
      getUserLeaves(userid, compid);
    }
  }, []);

  const getUserLeaves = (u, c) => {
    setLoading(true);
    LeaveApplicationService.userLeave(u, c).then((res) => {
      const data = res.data.userLeaves;
      setUserLeaves(data);
      setLoading(false);
    });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">User Leaves</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0  table-striped">
                        <thead>
                        <tr className="tableHeaderbg">
                            <th>Date</th>
                            <th>Paid Leave</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>{" "}
                            </>
                          ) : (
                            <>
                              {userLeaves.map((es) => (
                                <tr key={shortid.generate()}>
                                  <td className="tableset">{es.date}</td>

                                  {DataParser.userRoleTitle() === "HR" ||
                                  DataParser.userRoleTitle() ===
                                    "Company Admin" ||
                                  DataParser.userRoleTitle() === "Sub Admin" ? (
                                    <>
                                      <td className="tableset">
                                        <LeaveBadge
                                          item={es}
                                          api={
                                            LeaveApplicationService.userLeaveTypeUpdate
                                          }
                                        />
                                      </td>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <td className="tableset">
                                        {es.typ === 1 ? (
                                          <span className="btn btn-success waves-effect waves-light btn-sm">
                                            Yes
                                          </span>
                                        ) : (
                                          <span className="btn btn-danger waves-effect waves-light btn-sm">
                                            No
                                          </span>
                                        )}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLeaveEmployee;
