import React, { useEffect, useState, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import CreateLeads from "./CreateLeads";
import LeadsServices from "../../../Services/LeadsServices";
import DateField from "../../Reuseable/DateField";
import SelectFiled from "../../Reuseable/SelectFiled";
import AssigneField from "../../Reuseable/AssigneField";
import LeadPendingField from "../../Reuseable/LeadPendingField";
import EditLeads from "./EditLeads";
import CompanyService from "../../../Services/CompanyService";
import EditLeadsCopy from "./EditLeads copy";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import secureLocalStorage from "react-secure-storage";
import shortid from "shortid";
import { useLoadMore } from "../../../utlits/useLoadMore.js";

const Leads = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = DataParser.CompanyId();
  const companyIdentifier = DataParser.CompanyIdentifier();
  const companyIdetifier = DataParser.CompanyIdentifier();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ViewDrawerOpen, setViewDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [leadFilter, setLeadFilter] = useState("");
  const [sortFilter, setSortFilter] = useState("");
  const [dataTo, setDataTo] = useState([]);
  const [leadsOwner, setLeadsOwner] = useState([]);
  const [leadsByMe, setLeadsByMe] = useState([]);
  const [leadsSource, setLeadsSource] = useState([]);
  const [leadsDepartments, setLeadsDepartments] = useState([]);
  const [leadsPp, setleadsPp] = useState("");
  const [leadsCustomers, setLeadsCustomers] = useState([]);
  const [leadsStatus, setLeadsStatus] = useState([]);
  const [leadsComunication, setLeadsComunications] = useState([]);
  const [LeadAssignedToMe, setLeadAssignedToMe] = useState([]);
  const [allOtherLeads, setAllOtherLeads] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const [editID, setEditID] = useState("");

  // LEAD Detail fields
  const [title, setTitle] = useState(null);
  const [leadOwner, setLeadOwner] = useState(null);
  const [leadSource, setLeadSource] = useState(null);
  const [leadDueDate, setLeadDueDate] = useState(null);
  const [leadDepartmentId, setleadDepartmentId] = useState(null);
  const [leadPackage, setLeadPackage] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [leadSatus, setLeadStatus] = useState(null);
  const [leadCumincation, setLeadComunications] = useState(null);
  const [details, setDetails] = useState(null);
  const [owner, setOwner] = useState(null);
  const [dateLoader, setDateLoader] = useState(false);

  // View LEAD
  const [LeadeViewData, setLeadeViewData] = useState([]);
  const [pdeptid, setpdeptid] = useState("");
  const [leadCatrgotyPackList, setLeadCategoryPackList] = useState([]);
  const [LeadViewAttachment, setLeadViewAttachment] = useState(null);
  const [UpdateNotesAttac, setUpdateNotesAttac] = useState(null);
  const [leadAttachentImage, setLeadAttachmentIamge] = useState([]);
  const [LeadNotification, setLeadNotification] = useState(null);
  const [LeadNote, setLeadNote] = useState(null);

  const [createLoading, setCreateLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const filterrSearch = (data) => {
    if (searchValue === "") {
      return data;
    }
    return data.filter((el) =>
      el.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };
  const filterbyStatus = (data) => {
    if (leadFilter === "") {
      return data;
    }
    return data.filter((el) => el.lead_status === leadFilter);
  };
  const sortingByDifferent = (data) => {
    if (sortFilter === "") {
      return data;
    }
    if (sortFilter === "none") {
      return data;
    }
    if (sortFilter === "due-date") {
      return data.sort(
        (a, b) => moment(b.due_date).valueOf() - moment(a.due_date).valueOf()
      );
    }
    if (sortFilter === "assignee") {
      return data.sort((a, b) =>
        a?.owner.first_name?.localeCompare(b?.owner.first_name)
      );
      // return data.sort((p1, p2) =>
      //   p1.lead_owner < p2.lead_owner
      //     ? 1
      //     : p1.lead_owner > p2.lead_owner
      //     ? -1
      //     : 0
      // );
    }
    if (sortFilter === "status") {
      return data.sort((p1, p2) =>
        p1.lead_source < p2.lead_source
          ? 1
          : p1.lead_source > p2.lead_source
          ? -1
          : 0
      );
    }
  };
  const allFilter = (data) => {
    return sortingByDifferent(filterbyStatus(filterrSearch(data)));
  };

  useEffect(() => {
    if (location?.state?.isLeads) {
      setDrawerOpen(true);
      isElementLoaded("#createLeadss").then((selector) => {
        openLeadCreate();
      });
    }
    return () => {
      console.log("hi");
    };
  }, [location]);

  // useLayoutEffect(() => {

  // }, [])

  // useEffect(() => {
  //   const onPageLoad = () => {
  //     document.querySelector("#createLeadss").click();
  //     navigate(`/${companyIdetifier}/leads`, {
  //       state: {
  //         isLeads: true,
  //       },
  //     });
  //   };

  //   // Check if the page has already loaded
  //   if (document.readyState === 'complete') {
  //     onPageLoad();
  //   } else {
  //     window.addEventListener('load', onPageLoad);
  //     // Remove the event listener when component unmounts
  //     return () => window.removeEventListener('load', onPageLoad);
  //   }
  // }, [location]);

  useEffect(() => {
    const companyId = DataParser.CompanyId();
    setIsLoading(true);
    LeadsServices.getLeads(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }

      const data = res.data.leadbyme;
      const LeadsDataOwner = res.data.lead_owner;
      const LeadsDataSource = res.data.lead_source;
      const LeadsDataStatus = res.data.lead_status;
      const LeadsDataDepartments = res.data.leads_categories;
      const LeadsDataCustomers = res.data.contact;
      const LeadsDataComunication = res.data.communication;
      const allOtherLead = res.data.all_other_leads;
      const setLeadAssignedTo = res.data.lead_assigned_to_me;
      const LeadsPpp = res.data.lead_package;
      setLeadsOwner(LeadsDataOwner);
      setLeadsByMe(data);
      setLeadAssignedToMe(setLeadAssignedTo);
      setLeadsSource(LeadsDataSource);
      setLeadsDepartments(LeadsDataDepartments);
      setLeadsStatus(LeadsDataStatus);
      setLeadsCustomers(LeadsDataCustomers);
      setLeadsComunications(LeadsDataComunication);
      setleadsPp(LeadsPpp);
      if (allOtherLead !== "undefined") {
        setAllOtherLeads(allOtherLead);
        let combinedLeads = [...setLeadAssignedTo, ...data];
        setAllLeads(combinedLeads);
      } else {
        let combinedLeads = [...allOtherLead, ...setLeadAssignedTo, ...data];
        setAllLeads(combinedLeads);
      }
      setLoading(false);

      const upermission = DataParser.CompanyUserPermissions();
      setuserPermissions(upermission);
      setIsLoading(false);
    });

    if (location?.state?.isNotification) {
      const id = location?.state?.LeadId;

      LeadsServices.viewLead(id, { company_id: companyId }).then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
        const dataa = res.data.lead;
        setDataTo(dataa);
        setViewDrawerOpen(true);
      });
    }
  }, [sortFilter]);

  const reSetData = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsLoading(true);
    const companyId = DataParser.CompanyId();
    LeadsServices.getLeads(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.leadbyme;
      const LeadsDataOwner = res.data.lead_owner;
      const LeadsDataSource = res.data.lead_source;
      const LeadsDataStatus = res.data.lead_status;
      const LeadsDataDepartments = res.data.leads_categories;
      const LeadsDataCustomers = res.data.contact;
      const LeadsDataComunication = res.data.communication;
      const allOtherLead = res.data.all_other_leads;
      const setLeadAssignedTo = res.data.lead_assigned_to_me;

      setLeadsOwner(LeadsDataOwner);
      setLeadsByMe(data);
      setLeadsSource(LeadsDataSource);
      setLeadsDepartments(LeadsDataDepartments);
      setLeadsStatus(LeadsDataStatus);
      setLeadsCustomers(LeadsDataCustomers);
      setLeadsComunications(LeadsDataComunication);
      setLeadAssignedToMe(setLeadAssignedTo);
      if (allOtherLead !== "undefined") {
        setAllOtherLeads(allOtherLead);
        let combinedLeads = [...setLeadAssignedTo, ...data];
        setAllLeads(combinedLeads);
      } else {
        let combinedLeads = [...allOtherLead, ...setLeadAssignedTo, ...data];
        setAllLeads(combinedLeads);
      }
      setLoading(false);
      setDateLoader(false);
      setIsLoading(false);
    });
  };

  const setComplete = (e, item) => {
    e.preventDefault();

    const id = item.id;
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();

    const dataLead = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      status: "won",
    };
    LeadsServices.updateLeadStatus(id, dataLead).then((res) => {
      setLoading(true);
      reSetData(e);
    });
  };

  const LeadeView = (id) => {
    LeadsServices.viewLead(id, { company_id: companyId }).then((res) => {
      const response = res.data;
      setLeadeViewData(response.lead);
      setTitle(response.lead.title);
      setDetails(response.lead.details);
      setLeadOwner(response.lead.lead_owner);
      setLeadsSource(response.lead.lead_source);
      setContactName(response.lead.lead_contact_name);
      setLeadStatus(response.lead.status);
      setLeadPackage(response.lead.lead_package);
      setLeadComunications(response.lead.lead_com_source);
      setleadDepartmentId(response.lead.department_id);
      setLeadDueDate(response.lead.due_date);
      setOwner(response.lead?.created_by);
      setpdeptid(response.project_attached_departmet);
      const dpid =
        response?.lead?.department_id && response?.lead?.department_id
          ? response.lead.department_id
          : 0;
      LeadsServices.viewLeadCategoryPackage(dpid, companyId, null).then(
        (res) => {
          const data = res.data.category_packages;
          setLeadCategoryPackList(data);
        }
      );
    });
  };

  const LeadAttachmentImages = (id) => {
    LeadsServices.viewLead(id, { company_id: companyId }).then((res) => {
      const response = res.data;
      setLeadViewAttachment(response.lead.lead_attachments);
    });
  };

  const LeadNotesAttachmentImages = (id) => {
    LeadsServices.viewLead(id, { company_id: companyId }).then((res) => {
      const response = res.data;
      const attachmetns = response.lead.lead_notes_attachments;

      setUpdateNotesAttac(attachmetns);

      //
      const temp = [];
      for (let i = 0; i < attachmetns?.length; i++) {
        const index = attachmetns[i].lastIndexOf(".");
        const after = attachmetns[i].slice(index + 1);
        temp.push(after);
        setLeadAttachmentIamge(temp);
      }
    });
  };

  const LeadNotifications = (id) => {
    LeadsServices.viewLead(id, { company_id: companyId }).then((res) => {
      const response = res.data;
      setLeadNotification(response.lead.lead_notifications);
    });
  };
  const LeadNotes = (id) => {
    LeadsServices.viewLead(id, { company_id: companyId }).then((res) => {
      const response = res.data;
      setLeadNote(response.lead.lead_notes);
    });
  };

  const handelViewDrawer = (e, id) => {
    e.preventDefault();
    setEditLoading(true);
    setEditID(id);
    LeadeView(id);
    LeadAttachmentImages(id);
    LeadNotesAttachmentImages(id);
    LeadNotifications(id);
    LeadNotes(id);
    setDrawerOpen(!drawerOpen);
  };

  const updateLead = (e, id) => {
    setDateLoader(true);
    const newDate = moment(leadDueDate).format("YYYY-MM-DD");
    e.preventDefault();
    const leadUpdate = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      title: title,
      lead_owner: leadOwner,
      lead_source: leadSource,
      due_date: newDate,
      department_id: leadDepartmentId,
      lead_package: leadPackage,
      contact_name: contactName,
      lead_status: leadSatus,
      com_source: leadCumincation,
      details: details,
    };
    console.log("alosd: ", leadUpdate);
    LeadsServices.updateLead(id, leadUpdate).then((res) => {
      reSetData(e);
    });
  };

  const openLeadCreate = () => {
    document.querySelector("#createLeadss").click();
    navigate(`/${companyIdetifier}/leads`, {
      state: null,
    });
  };

  const isElementLoaded = async (selector) => {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  };

  const [items, loadMore, hasMore] = useLoadMore(LeadAssignedToMe, 10);

  return (
    <>
      <Helmet>
        <title>Leads - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <CreateLeads
              offcanvasid={"offcanvasRightCreate"}
              labelledby={"offcanvasRightLabel"}
              leadsOwner={leadsOwner}
              leadsSource={leadsSource}
              leadsDepartments={leadsDepartments}
              leadsCustomers={leadsCustomers}
              leadsStatus={leadsStatus}
              leadsComunication={leadsComunication}
              setDrawerOpen={setDrawerOpen}
              reFreshData={(e) => reSetData(e)}
              createLoading={createLoading}
              setCreateLoading={setCreateLoading}
              leadCatrgotyPackList={leadCatrgotyPackList}
            />
            {userPermissions.indexOf("leads-edit") !== -1 ? (
              <EditLeads
                LeadNotes={(e) => LeadNotes(e)}
                offcanvasid={`offcanvasRight2`}
                labelledby={`offcanvasRightLabel2`}
                editID={editID}
                title={title}
                leadOwner={leadOwner}
                leadSource={leadSource}
                leadDueDate={leadDueDate}
                leadDepartmentId={leadDepartmentId}
                leadPackage={leadPackage}
                contactName={contactName}
                leadSatus={leadSatus}
                leadCumincation={leadCumincation}
                details={details}
                owner={owner}
                setTitle={setTitle}
                setLeadOwner={setLeadOwner}
                setLeadSource={setLeadSource}
                setLeadDueDate={setLeadDueDate}
                setleadDepartmentId={setleadDepartmentId}
                setLeadPackage={setLeadPackage}
                setContactName={setContactName}
                setLeadStatus={setLeadStatus}
                setLeadComunications={setLeadComunications}
                setDetails={setDetails}
                setOwner={setOwner}
                leadsOwner={leadsOwner}
                leadsSource={leadsSource}
                leadsDepartments={leadsDepartments}
                leadsCustomers={leadsCustomers}
                leadsStatus={leadsStatus}
                leadsComunication={leadsComunication}
                LeadViewAttachment={LeadViewAttachment}
                setLeadViewAttachment={setLeadViewAttachment}
                UpdateNotesAttac={UpdateNotesAttac}
                setUpdateNotesAttac={setUpdateNotesAttac}
                LeadeViewData={LeadeViewData}
                setLeadeViewData={setLeadeViewData}
                LeadNotification={LeadNotification}
                setLeadNotification={setLeadNotification}
                LeadNotifications={LeadNotifications}
                LeadNote={LeadNote}
                setLeadNote={setLeadNote}
                LeadNotesAttachmentImages={LeadNotesAttachmentImages}
                userPermissions={userPermissions}
                reFreshData={(e) => reSetData(e)}
                updateLead={updateLead}
                editLoading={editLoading}
                setEditLoading={setEditLoading}
                dateLoader={dateLoader}
                setDateLoader={setDateLoader}
                LeadAttachmentImages={LeadAttachmentImages}
              />
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-3 button">
                {userPermissions.indexOf("leads-create") !== -1 ? (
                  <Link
                    id="createLeadss"
                    to="#"
                    className="btnsss"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightCreate"
                    aria-controls="offcanvasRight"
                    onClick={(e) => setCreateLoading(true)}
                  >
                    <i className="uil-plus" /> New Lead
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="col-9 sorts">
                <div className="float-end">
                  <form className="d-inline-flex">
                    <label
                      className="form-check-label mx-50 m-0 shink my-2 me-2"
                      htmlFor="order-selectinput"
                    >
                      <i className="uil-filter"></i>
                    </label>
                    <select
                      className="form-select formselectset"
                      id="order-selectinput"
                      style={{
                        border: "none",
                      }}
                      onChange={(e) => {
                        setSortFilter(e.target.value);
                      }}
                    >
                      <option selected="">Sort</option>
                      <option value="none">None</option>
                      <option value="due-date">Due Date</option>
                      <option value="assignee">Asignee</option>
                      <option value="status">Status</option>
                    </select>
                  </form>
                  <select
                    style={{ border: "none" }}
                    className="init"
                    value={leadFilter}
                    onChange={(e) => setLeadFilter(e.target.value)}
                  >
                    <option value="">All Leads</option>
                    <option value="pending">Pending</option>
                    <option value="in-discussion">In Discussion</option>
                    <option value="won">Won</option>
                    <option value="lost">Lost</option>
                    <option value="potential">Potential</option>
                    <option value="dead">Dead</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row app-search">
              <div className="col-md-12">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search..."
                  id="example-text-input"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="uil-search" />
              </div>
            </div>
            <div className="todo-task-list list-group ps w-100">
              <div className="task_heading_column">
                <div className="task_name_column">
                  <p>Title</p>
                </div>
                <div className="Projects_name_column">
                  <p>Status</p>
                </div>
                <div className="Projects_name_column ">
                  <p>Assignee</p>
                </div>
                <div className="Projects_name_column">
                  <p>Lead Source</p>
                </div>
                <div className="date_name_column">
                  <p>Due Date</p>
                </div>
              </div>
            </div>

            {isLoading ? (
              <>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Fade big>
                <>
                  {LeadAssignedToMe?.length !== 0 ? (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <i className="uil-arrow-down" /> Lead Assigned To Me
                        </span>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {allFilter(items && items)?.map((item, index) => (
                          <div
                            key={shortid.generate()}
                            className="todo-task-list list-group ps w-100"
                          >
                            <div className="task_heading_column">
                              <div className="task_name_column">
                                <input
                                  className="form-check-input"
                                  onChange={(e) => {
                                    setComplete(e, item);
                                  }}
                                  type="checkbox"
                                  style={{ borderRadius: "100px" }}
                                  name="formRadios"
                                  id={`formRadios${item.id}`}
                                  checked={item.lead_status === "won"}
                                />
                                <div className="form-check mt-4 w-100 h-100">
                                  <Link
                                    to="#"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target={`#offcanvasRight2`}
                                    aria-controls={`offcanvasRight2`}
                                    onClick={(e) =>
                                      handelViewDrawer(e, item.id)
                                    }
                                  >
                                    {item.lead_status === "won" ? (
                                      <del className="StrikedThrouhgh">
                                        <p
                                          className="form-check-label mx-50 m-0 shink"
                                          style={{
                                            color: "#404e67",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.title}
                                        </p>
                                      </del>
                                    ) : (
                                      <p
                                        className="form-check-label mx-50 m-0 shink"
                                        style={{
                                          color: "#404e67",

                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.title}
                                      </p>
                                    )}
                                  </Link>
                                </div>
                              </div>
                              <div className="Projects_name_column">
                                {userPermissions.indexOf("leads-edit") !==
                                -1 ? (
                                  <LeadPendingField
                                    item={item}
                                    DefaultStatus={item?.lead_status}
                                    Status={leadsStatus}
                                    Services={LeadsServices.updateLeadStatus}
                                    data={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>{item.lead_status}</span>
                                )}
                              </div>
                              <div className="Projects_name_column ">
                                {userPermissions.indexOf("leads-edit") !==
                                -1 ? (
                                  <AssigneField
                                    item={item}
                                    assigne={leadsOwner}
                                    Services={LeadsServices.updateLeadAssigne}
                                    Owner={item.owner}
                                    newOwner={item.lead_owner}
                                    data={(e) => reSetData(e)}
                                    reFreshData={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>
                                    {item.lead_owner.first_name}{" "}
                                    {item.lead_owner.last_name}
                                  </span>
                                )}
                              </div>
                              <div className="Projects_name_column">
                                {userPermissions.indexOf("leads-edit") !==
                                -1 ? (
                                  <SelectFiled
                                    item={item}
                                    reFreshData={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>{item.lead_source}</span>
                                )}
                              </div>
                              <div className="date_name_column">
                                {dateLoader ? (
                                  <>
                                    {" "}
                                    <Skeleton />
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <p>
                                      <DateField
                                        item={item}
                                        defaultDate={item?.due_date}
                                        Services={
                                          LeadsServices.updateLeadDueDate
                                        }
                                        data={(e) => reSetData(e)}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <>
                          {hasMore && (
                            <div className="row">
                              <Link
                                className="text-center"
                                onClick={loadMore}
                                to="#"
                              >
                                <span
                                  type="button"
                                  className="btn btnsss waves-effect waves-light"
                                >
                                  Load More
                                </span>
                              </Link>
                            </div>
                          )}
                        </>
                      </div>
                    </Fade>
                  ) : null}
                  {leadsByMe?.length !== 0 ? (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <i className="uil-arrow-down" /> Lead Assigned By Me
                        </span>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {allFilter(leadsByMe && leadsByMe)?.map(
                          (item, index) => (
                            <div
                              key={shortid.generate()}
                              className="todo-task-list list-group ps w-100"
                            >
                              <div className="task_heading_column">
                                <div className="task_name_column">
                                  <input
                                    className="form-check-input"
                                    onChange={(e) => {
                                      setComplete(e, item);
                                    }}
                                    type="checkbox"
                                    style={{ borderRadius: "100px" }}
                                    name="formRadios"
                                    id={`formRadios${item.id}`}
                                    checked={item.lead_status === "won"}
                                  />
                                  <div className="form-check mt-4 w-100 h-100">
                                    <Link
                                      to="#"
                                      data-bs-toggle="offcanvas"
                                      data-bs-target={`#offcanvasRight2`}
                                      aria-controls={`offcanvasRight2`}
                                      onClick={(e) =>
                                        handelViewDrawer(e, item.id)
                                      }
                                    >
                                      {item.lead_status === "won" ? (
                                        <del className="StrikedThrouhgh">
                                          <p
                                            className="form-check-label mx-50 m-0 shink"
                                            style={{
                                              color: "#404e67",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.title}
                                          </p>
                                        </del>
                                      ) : (
                                        <p
                                          className="form-check-label mx-50 m-0 shink"
                                          style={{
                                            color: "#404e67",

                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.title}
                                        </p>
                                      )}
                                    </Link>
                                  </div>
                                </div>
                                <div className="Projects_name_column">
                                  {userPermissions.indexOf("leads-edit") !==
                                  -1 ? (
                                    <LeadPendingField
                                      item={item}
                                      Status={leadsStatus}
                                      DefaultStatus={item.lead_status}
                                      Services={LeadsServices.updateLeadStatus}
                                      data={(e) => reSetData(e)}
                                    />
                                  ) : (
                                    <span>{item.lead_status}</span>
                                  )}
                                </div>
                                <div className="Projects_name_column ">
                                  {userPermissions.indexOf("leads-edit") !==
                                  -1 ? (
                                    <AssigneField
                                      item={item}
                                      assigne={leadsOwner}
                                      Services={LeadsServices.updateLeadAssigne}
                                      Owner={item.owner}
                                      newOwner={item.lead_owner}
                                      data={(e) => reSetData(e)}
                                      reFreshData={(e) => reSetData(e)}
                                    />
                                  ) : (
                                    <span>
                                      {item.lead_owner.first_name}{" "}
                                      {item.lead_owner.last_name}
                                    </span>
                                  )}
                                </div>
                                <div className="Projects_name_column">
                                  {userPermissions.indexOf("leads-edit") !==
                                  -1 ? (
                                    <SelectFiled
                                      item={item}
                                      reFreshData={(e) => reSetData(e)}
                                    />
                                  ) : (
                                    <span>{item.lead_source}</span>
                                  )}
                                </div>
                                <div className="date_name_column">
                                  {dateLoader ? (
                                    <>
                                      <Skeleton />
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <p>
                                        <DateField
                                          item={item}
                                          Services={
                                            LeadsServices.updateLeadDueDate
                                          }
                                          defaultDate={item?.due_date}
                                          data={(e) => reSetData(e)}
                                        />
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </Fade>
                  ) : null}
                  {allOtherLeads?.length !== 0 ? (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <i className="uil-arrow-down" /> All Other Leads
                        </span>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {allFilter(allOtherLeads && allOtherLeads)?.map(
                          (item, index) => (
                            <div
                              key={shortid.generate()}
                              className="todo-task-list list-group ps w-100"
                            >
                              <div className="task_heading_column">
                                <div className="task_name_column">
                                  <input
                                    className="form-check-input"
                                    onChange={(e) => {
                                      setComplete(e, item);
                                    }}
                                    type="checkbox"
                                    style={{ borderRadius: "100px" }}
                                    name="formRadios"
                                    id={`formRadios${item.id}`}
                                    checked={item.lead_status === "won"}
                                  />
                                  <div className="form-check mt-4 w-100 h-100">
                                    <Link
                                      to="#"
                                      data-bs-toggle="offcanvas"
                                      data-bs-target={`#offcanvasRight2`}
                                      aria-controls={`offcanvasRight2`}
                                      onClick={(e) =>
                                        handelViewDrawer(e, item.id)
                                      }
                                    >
                                      {item.lead_status === "won" ? (
                                        <del className="StrikedThrouhgh">
                                          <p
                                            className="form-check-label mx-50 m-0 shink"
                                            style={{
                                              color: "#404e67",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.title}
                                          </p>
                                        </del>
                                      ) : (
                                        <p
                                          className="form-check-label mx-50 m-0 shink"
                                          style={{
                                            color: "#404e67",

                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.title}
                                        </p>
                                      )}
                                    </Link>
                                  </div>
                                </div>
                                <div className="Projects_name_column">
                                  {userPermissions.indexOf("leads-edit") !==
                                  -1 ? (
                                    <LeadPendingField
                                      item={item}
                                      Status={leadsStatus}
                                      DefaultStatus={item.lead_status}
                                      Services={LeadsServices.updateLeadStatus}
                                      data={(e) => reSetData(e)}
                                    />
                                  ) : (
                                    <span>{item.lead_status}</span>
                                  )}
                                </div>
                                <div className="Projects_name_column ">
                                  {userPermissions.indexOf("leads-edit") !==
                                  -1 ? (
                                    <AssigneField
                                      item={item}
                                      assigne={leadsOwner}
                                      Services={LeadsServices.updateLeadAssigne}
                                      Owner={item.owner}
                                      newOwner={item.lead_owner}
                                      data={(e) => reSetData(e)}
                                      reFreshData={(e) => reSetData(e)}
                                    />
                                  ) : (
                                    <span>
                                      {item.lead_owner.first_name}{" "}
                                      {item.lead_owner.last_name}
                                    </span>
                                  )}
                                </div>
                                <div className="Projects_name_column">
                                  {userPermissions.indexOf("leads-edit") !==
                                  -1 ? (
                                    <SelectFiled
                                      item={item}
                                      reFreshData={(e) => reSetData(e)}
                                    />
                                  ) : (
                                    <span>{item.lead_source}</span>
                                  )}
                                </div>
                                <div className="date_name_column">
                                  {dateLoader ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {" "}
                                      <p>
                                        <DateField
                                          item={item}
                                          Services={
                                            LeadsServices.updateLeadDueDate
                                          }
                                          data={(e) => reSetData(e)}
                                          defaultDate={item?.due_date}
                                        />
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </Fade>
                  ) : null}
                </>
              </Fade>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Leads;
