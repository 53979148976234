import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import shortid from "shortid";
import LeadsServices from "../../../Services/LeadsServices";
import DataParser from "../../../utlits/Dataparser";

const CreateLeads = (props) => {
  const {
    leadsOwner,
    leadsSource,
    leadsDepartments,
    leadsCustomers,
    leadsStatus,
    leadsComunication,
    reFreshData,
    offcanvasid,
    labelledby,
    createLoading,
    setCreateLoading,
  } = props;

  const navigate = useNavigate();

  const companyId = DataParser.CompanyId();
  const companyIdentifier = DataParser.CompanyIdentifier();

  // lead drawer States
  const [assigne, setAssigne] = useState("");
  const [testData, setTestData] = useState(null);
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [leadOwner, setLeadOwner] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [leadDepartmentId, setleadDepartmentId] = useState("");
  const [leadPackage, setLeadPackage] = useState("");
  const [contactName, setContactName] = useState("");
  const [leadSatus, setLeadStatus] = useState("");
  const [leadCumincation, setLeadComunications] = useState("");
  const [details, setDetails] = useState("");
  const [leadCatrgotyPackList, setLeadCategoryPackList] = useState([]);
  const [leadCategoryState, setLeadCategoryState] = useState(false);

  //lead customer state
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerDesgination, setCustomerDesignation] = useState("");
  const [customerCompany, setCustomerCompany] = useState("");

  setTimeout(() => {
    setCreateLoading(false);
  }, 2000);

  useEffect(() => {
    const companyId = DataParser.CompanyId();
    const userId = DataParser.UserId();
    //const datee = moment().format("DD-MM-YYYY");
    const datee = DataParser.getDateinFormat();
    setDate(datee);
    setLeadStatus("pending");

    setLeadOwner(userId);
  }, []);

  useEffect(() => {
    const newArr = leadsOwner.map((obj) => {
      if (obj.id === leadOwner) {
        return { ...obj, name: "Assign me" };
      }
      return obj;
    });
    setTestData(newArr);
  }, [leadsOwner]);

  const LeadCatePack = (e) => {
    setleadDepartmentId(e.target.value);
    const id = e.target.value;
    LeadsServices.viewLeadCategoryPackage(id, companyId).then((res) => {
      const data = res.data.category_packages;
      setLeadCategoryPackList(data);
      setLeadCategoryState(true);
    });
  };

  const onDateChange = (date) => {
    setDate(date);
  };

  const createCustomers = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const customerData = {
      company_id: companyId,
      name: customerName,
      designation: customerDesgination,
      company: customerCompany,
      email: customerEmail,
      mobile: customerPhone,
      phone: customerPhone,
    };
    LeadsServices.createCustomer(customerData).then((res) => {
      reFreshData(e);
      setContactName(res.id);
      setCustomerName("");
      setCustomerPhone("");
      setCustomerEmail("");
      setCustomerDesignation("");
      setCustomerCompany("");
      var link = document.getElementById("closeButton2");
      link.click();
    });
  };

  const createLead = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();
    const leadSubmit = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      title: title,
      lead_owner: leadOwner,
      lead_source: leadSource,
      due_date: date,
      department_id: leadDepartmentId,
      lead_package: leadPackage,
      contact_name: contactName,
      lead_status: leadSatus,
      lead_com: leadCumincation,
      details: details,
    };
    LeadsServices.createLead(leadSubmit).then((res) => {
      if (res.Good) {
        var link = document.getElementById("closeButton");
        link.click();
        reFreshData(e);
        setTitle("");
        setLeadOwner("");
        setLeadSource("");
        setleadDepartmentId("");
        setLeadPackage("");
        setContactName("");
        setLeadStatus("");
        setLeadComunications("");
        setDetails("");
        setLeadCategoryPackList("");
        setLeadCategoryState("");
        navigate(`/${DataParser.CompanyIdentifierRole()}/leads`);
      }
    });
    reFreshData(e);
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={offcanvasid}
        aria-labelledby={labelledby}
        style={{ width: "650px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>Leads</h5>
          <button
            type="button"
            id="closeButton"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          {createLoading ? (
            <>
              <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <div className="controls row">
                        <div className="col-sm-12 title_font_size">
                          <Skeleton
                            style={{
                              width: "95%",
                              paddingTop: "10px",
                              marginLeft: "1rem",
                              paddingBottom: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <div className="form-group  mt-2">
                      <div className="controls row">
                        <div className="col-sm-3 pl-2">
                          <Skeleton
                            style={{
                              width: "95%",
                              marginLeft: "1rem",
                              marginTop: "15px",
                            }}
                          />
                        </div>
                        <div className="col-sm-4">
                          <Skeleton
                            style={{
                              width: "95%",
                              marginLeft: "1rem",
                              marginTop: "15px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row  mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row  mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row  mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row  mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row  mt-2">
                      <div className="col-sm-3 pl-2">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Skeleton
                          style={{
                            width: "95%",
                            marginLeft: "1rem",
                            marginTop: "15px",
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <form onSubmit={(e) => createLead(e)}>
              <div style={{paddingRight:"2rem"}} className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <div className="controls row">
                        <div className="col-sm-12 title_font_size">
                          <input
                            type="text"
                            id="titleid"
                            className="form-control w-100 inputset custominputTitle canvous-title-field"
                            placeholder="Title"
                            style={{ border: "0px" }}
                            name="title"
                            required
                            data-validation-required-message="This role field is required"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <label
                          className="offcanvas_labels"
                          htmlFor="basicSelect"
                        >
                          Assignee<span className="alert_star">*</span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <select
                          className="form-control selectset custominput"
                          style={{ border: "0px" }}
                          name="lead_owner"
                          id="basicSelect"
                          required
                          onChange={(e) => setLeadOwner(e.target.value)}
                          value={leadOwner}
                        >
                          <option value="">Please Select</option>
                          {testData?.map((els) => (
                            <option key={shortid.generate()} value={els.id}>
                              {els.name !== undefined
                                ? els.name
                                : els.first_name + " " + els.last_name}
                            </option>
                          ))}
                          {/* {leadsOwner &&
                            leadsOwner.map((el, index) => (
                              <option key={index} value={el.id}>
                                {leadOwner === el.id
                                  ? "Assign me"
                                  : el.first_name + " " + el.last_name}
                              </option>
                            ))} */}
                        </select>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <label
                          className="offcanvas_labels"
                          htmlFor="basicSelect2"
                        >
                          Source<span className="alert_star">*</span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <select
                          className="form-control selectset custominput"
                          name="lead_source"
                          id="basicSelect2"
                          required
                          value={leadSource}
                          onChange={(e) => setLeadSource(e.target.value)}
                          style={{ border: "0px" }}
                        >
                          {leadsSource &&
                            leadsSource.map((el, index) => (
                              <option key={shortid.generate()}  value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <div className="form-group  mt-2">
                      <div className="controls row">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect6"
                          >
                            Due Date
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <DatePicker
                            disabledDate={(current) => {
                              let customDate = moment().format("YYYY-MM-DD");
                              return (
                                current &&
                                current < moment(customDate, "YYYY-MM-DD")
                              );
                            }}
                            allowClear={false}
                            bordered={false}
                            value={moment(date)}
                            onChange={onDateChange}
                            format={"DD-MM-YYYY"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row  mt-2">
                      <div className="col-sm-3 pl-2">
                        <label
                          className="offcanvas_labels"
                          htmlFor="basicSelect3"
                        >
                          Lead Category<span className="alert_star">*</span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <select
                          className="dept form-control selectset custominput"
                          name="department_id"
                          id="basicSelect3"
                          required
                          style={{ border: "0px" }}
                          value={leadDepartmentId}
                          onChange={(e) => LeadCatePack(e)}
                        >
                          <option value="">Select Option</option>
                          {leadsDepartments &&
                            leadsDepartments.map((el) => (
                              <option key={shortid.generate()}  value={el.id}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </fieldset>
                  </div>
                  {leadCategoryState ? (
                    <div className="col-12 col-sm-12">
                      <fieldset className="form-group row  mt-2">
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect3"
                          >
                            Category Package
                            <span className="alert_star">*</span>
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="dept form-control selectset custominput"
                            name="department_id"
                            id="basicSelect3"
                            required
                            style={{ border: "0px" }}
                            value={leadPackage}
                            onChange={(e) => setLeadPackage(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {leadCatrgotyPackList &&
                              leadCatrgotyPackList.map((els) => (
                                <option key={shortid.generate()} value={els.id}>
                                  {els.lead_package}
                                </option>
                              ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                  ) : null}

                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <label
                          className="offcanvas_labels"
                          htmlFor="basicSelect5"
                        >
                          Customer<span className="alert_star">*</span>
                        </label>
                      </div>
                      <div className="col-sm-4 d-flex" id="cust">
                        <select
                          className="form-control select2 select2-hidden-accessible selectset custominput"
                          name="contact_name"
                          id="basicSelect5"
                          required
                          data-select2-id="basicSelect5"
                          tabIndex={-1}
                          aria-hidden="true"
                          style={{ border: "0px" }}
                          value={contactName}
                          onChange={(e) => setContactName(e.target.value)}
                        >
                          <option value="">Select Option</option>
                          {leadsCustomers &&
                            leadsCustomers.map((el, index) => (
                              <option key={shortid.generate()} value={el.id}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-sm-4 d-flex">
                        <span
                          style={{ cursor: "pointer", placeSelf: "center" }}
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                        >
                          <span>+</span> Add Customer
                        </span>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <label
                          className="offcanvas_labels"
                          htmlFor="basicSelect7"
                        >
                          Lead Status<span className="alert_star">*</span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <select
                          className="form-control selectset custominput"
                          name="lead_status"
                          id="basicSelect7"
                          value={leadSatus}
                          onChange={(e) => setLeadStatus(e.target.value)}
                          required
                          style={{
                            textTransform: "capitalize",
                            border: "0px",
                          }}
                        >
                          <option value="">Select Option</option>
                          {leadsStatus &&
                            leadsStatus.map((el, index) => (
                              <option key={shortid.generate()} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-12 col-sm-12">
                    <fieldset className="form-group row mt-2">
                      <div className="col-sm-3 pl-2">
                        <label
                          className="offcanvas_labels"
                          htmlFor="basicSelect8"
                        >
                          Communication Source
                          <span className="alert_star">*</span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <select
                          className="form-control selectset custominput"
                          name="com_source"
                          id="basicSelect8"
                          required
                          value={leadCumincation}
                          onChange={(e) => setLeadComunications(e.target.value)}
                          style={{ border: "0px" }}
                        >
                          <option value="">Select Option</option>
                          {leadsComunication &&
                            leadsComunication.map((el, index) => (
                              <option key={shortid.generate()} value={el.id}>
                                {el.value}
                              </option>
                            ))}
                        </select>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <div
                        className="controls row"
                        style={{ marginRight: "0px" }}
                      >
                        <div className="col-sm-3 pl-2">
                          <label
                            className="offcanvas_labels"
                            htmlFor="basicSelect9"
                          >
                            Details
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <textarea
                            className="form-control w-100 autosize textset canvous-textarea-field canvous-textarea-field-edit"
                            rows={3}
                            placeholder="Details"
                            id="basicSelect9"
                            name="details"
                            data-validation-required-message="This role field is required"
                            data-gramm="false"
                            wt-ignore-input="true"
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          <div
            id="myModal"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="myModalLabel">
                    Add New Customer
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <form>
                  <div className="modal-body">
                    <input
                      type="text"
                      id="customer-name"
                      className="form-control mb-3"
                      placeholder="Customer Name*"
                      required
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                    <input
                      type="tel"
                      id="customer-phone"
                      className="form-control mb-3"
                      placeholder="Customer Phone Number*"
                      required
                      value={customerPhone}
                      onChange={(e) => setCustomerPhone(e.target.value)}
                    />
                    <input
                      type="email"
                      id="customer-email"
                      className="form-control mb-3"
                      placeholder="Customer Email"
                      value={customerEmail}
                      onChange={(e) => setCustomerEmail(e.target.value)}
                    />
                    <input
                      type="text"
                      id="customer-designaton"
                      className="form-control mb-3"
                      placeholder="Customer designaton"
                      value={customerDesgination}
                      onChange={(e) => setCustomerDesignation(e.target.value)}
                    />
                    <input
                      type="text"
                      id="customer-company"
                      className="form-control mb-3"
                      placeholder="Customer Company"
                      value={customerCompany}
                      onChange={(e) => setCustomerCompany(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light waves-effect"
                      id="closeButton2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      onClick={(e) => createCustomers(e)}
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLeads;
