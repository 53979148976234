import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import CategoryService from "../../../Services/CategoryService";
import StatusBadge from "../../Reuseable/StatusBadge";
import CategoryOwnerField from "../../Reuseable/CategoryOwnerField";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const Categories = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const companyId = DataParser.CompanyId();
  const [isLoading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryEditName, setCategoryEditName] = useState("");
  const [editId, setEditId] = useState("");

  const getData = () => {
    setLoading(true);
    CategoryService.getCategories(companyId).then((res) => {
      const dataCategory = res.data.categories;
      setCategoryList(dataCategory);
      const upermission = DataParser.CompanyUserPermissions();
      setuserPermissions(upermission);
      setLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const handelDelete = (e, id) => {
    e.preventDefault();
    const companyID = DataParser.CompanyId();
    const newData = categoryList.filter((el) => el.id !== id);
    setCategoryList(newData);
    CategoryService.deleteCategories(id, companyID).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const data = { company_id: companyId, name: categoryName };
    CategoryService.createCategories(data).then((res) => {
      if (res.Good) {
        var link = document.getElementById("categoryModalCreate");
        link.click();
        setCategoryName("");
        getData();
      }
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };
  const handleOpenUpadte = (e, id) => {
    setEditId(id);
    CategoryService.showCategories(id, companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.category[0].name;
      setCategoryEditName(data);
    });
  };
  const handelEditSubmit = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const data = { company_id: companyId, name: categoryEditName };
    CategoryService.updateCategories(editId, data).then((res) => {
      if (res.Good) {
        var link = document.getElementById("categoryModalEdit");
        link.click();
        setCategoryName("");
        getData();
      }
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Categories - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-cats-center justify-content-between">
                  <h4 className="mb-0">All Categories</h4>
                  <div className="page-title-right">
                    {userPermissions.indexOf("categories-create") !== -1 ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#createCategoryModal"
                      >
                        Create Category
                      </button>
                    ) : (
                      ""
                    )}

                    {/* Create modal content */}
                    <div
                      id="createCategoryModal"
                      className="modal fade"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <form onSubmit={(e) => handelSubmit(e)}>
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Category
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                id="categoryModalCreate"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div
                                  className="col-12 mb-3 d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-4">
                                    <label
                                      className="form-label"
                                      style={{
                                        paddingRight: "20px",
                                        paddingBottom: "0px",
                                        marginBottom: "0px",
                                      }}
                                      htmlFor="CategoryPackages"
                                    >
                                      Category Packages
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="CategoryPackages"
                                      placeholder="Category Packages"
                                      required
                                      value={categoryName}
                                      onChange={(e) =>
                                        setCategoryName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                onClick={(e) => handelSubmit(e)}
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                      </form>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Name</th>
                            <th>Status</th>
                            <th>Project Ownership</th>
                            <th>Creation Date</th>
                            <th>Add Category Packages</th>
                            <th> Add Catagory Reason</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {categoryList &&
                                categoryList.map((cat) => (
                                  <tr key={cat.id}>
                                    <td className="tableset">{cat.name}</td>
                                    <td className="tableset">
                                      {userPermissions.indexOf("leads-edit") !==
                                      -1 ? (
                                        <StatusBadge
                                          item={cat}
                                          api={
                                            CategoryService.updateCategoryStatus
                                          }
                                        />
                                      ) : (
                                        <>
                                          {cat.status === "1" ? (
                                            <span className="btn btn-success waves-effect waves-light btn-sm">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="btn btn-danger waves-effect waves-light btn-sm">
                                              Deactive
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf(
                                        "categories-edit"
                                      ) !== -1 ? (
                                        <CategoryOwnerField item={cat} />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {moment(cat.created_at).format(
                                        "D/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf(
                                        "categories-edit"
                                      ) !== -1 ? (
                                        <Link
                                          to={`/${DataParser.CompanyIdentifierRole()}/categories/lead_types/${
                                            cat.id
                                          }`}
                                        >
                                          <span className="btn btn-success waves-effect waves-light btn-sm">
                                            Add Category Packages
                                          </span>
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf(
                                        "categories-edit"
                                      ) !== -1 ? (
                                        <Link to="#">
                                          <span className="btn btn-success waves-effect waves-light btn-sm">
                                            Add Category Reason
                                          </span>
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td className="tableset">
                                      {userPermissions.indexOf(
                                        "categories-delete"
                                      ) !== -1 ? (
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            handelDelete(e, cat.id)
                                          }
                                          className="invoice-action-view mr-1"
                                        >
                                          <i className="uil-trash iconscolor" />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                      {userPermissions.indexOf(
                                        "categories-edit"
                                      ) !== -1 ? (
                                        <Link to="#">
                                          <i
                                            onClick={(e) =>
                                              handleOpenUpadte(e, cat.id)
                                            }
                                            className="uil-pen iconscolor"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editCategoryModal"
                                          />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}

                          <div
                            id="editCategoryModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <form onSubmit={(e) => handelEditSubmit(e)}>
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="myModalLabel"
                                    >
                                      Edit Category
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      id="categoryModalEdit"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div
                                        className="col-12 mb-3 d-flex align-self-center"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div className="col-md-4">
                                          <label
                                            className="form-label"
                                            style={{
                                              paddingRight: "20px",
                                              paddingBottom: "0px",
                                              marginBottom: "0px",
                                            }}
                                            htmlFor="CategoryPackages"
                                          >
                                            Category Packages
                                          </label>
                                        </div>
                                        <div className="col-md-8">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="CategoryPackages"
                                            placeholder="Category Packages"
                                            required
                                            value={categoryEditName}
                                            onChange={(e) =>
                                              setCategoryEditName(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-light waves-effect"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      onClick={(e) => handelEditSubmit(e)}
                                      type="button"
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Save changes
                                    </button>
                                  </div>
                                </div>
                                {/* /.modal-content modal-dialog-centere */}
                              </div>
                            </form>
                            {/* /.modal-dialog */}
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
