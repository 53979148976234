import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import moment from "moment/moment";
import DataParser from "../../../utlits/Dataparser";
import { Helmet } from "react-helmet";
import StatusBadge from "../../Reuseable/StatusBadge";
import JobStatusService from "../../../Services/JobStatusService";
import secureLocalStorage from "react-secure-storage";

const JobStatus = () => {
  const navigate = useNavigate();
  const [JobStatus, setJobStatus] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);

  const getJobss = () => {
    const companyId = DataParser.CompanyId();
    JobStatusService.getJobStatusService(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const dataset = res.data.jobstatus;
      setJobStatus(dataset);
    });
  };

  useEffect(() => {
    getJobss();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const handelDelete = (e, item) => {
    e.preventDefault();
    const id = item.id;
    const newData = JobStatus.filter((el) => el.id !== id);
    setJobStatus(newData);
    
    JobStatusService.deleteJobStatus(id)
      .then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
        getJobss();
      })
      .catch((err) => {});
  };

  const handelEdit = (e, item) => {
    e.preventDefault();
    navigate(`/${DataParser.CompanyIdentifierRole()}/jobemplaoye/edit`, {
      state: {
        data: item,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Job Status - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Job Status</h4>
                  <div className="page-title-right">
                    {userPermissions.indexOf("departments-create") !== -1 ? (
                      <Link
                        to={`/${DataParser.CompanyIdentifierRole()}/jobemplaoye/create`}
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Create Job Status
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Name</th>
                            <th>Status</th>
                            <th>Creation Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {JobStatus &&
                            JobStatus.map((item, index) => (
                              <tr key={item.id}>
                               
                                <td className="tableset">{item.name}</td>
                                <td className="tableset">
                                  {userPermissions.indexOf(
                                    "jobstatus-edit"
                                  ) !== -1 ? (
                                    <StatusBadge
                                      item={item}
                                      api={
                                        JobStatusService.updateJobStatusStatus
                                      }
                                    />
                                  ) : (
                                    <>
                                      {item.status === "1" ? (
                                        <span className="btn btn-success waves-effect waves-light btn-sm">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="btn btn-danger waves-effect waves-light btn-sm">
                                          Deactive
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className="tableset">
                                  {moment(item.created_at).format("D/MM/YYYY")}
                                </td>
                                <td className="tableset">
                                  {userPermissions.indexOf(
                                    "jobstatus-delete"
                                  ) !== -1 ? (
                                    <Link
                                      to="#"
                                      className="cursor-pointer iconscolor"
                                      onClick={(e) => handelDelete(e, item)}
                                    >
                                      <i className="uil-trash iconscolor" />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  {userPermissions.indexOf(
                                    "departments-edit"
                                  ) !== -1 ? (
                                    <Link
                                      to="#"
                                      className="cursor-pointer iconscolor"
                                      onClick={(e) => handelEdit(e, item)}
                                    >
                                      <i className="uil-pen iconscolor" />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobStatus;
