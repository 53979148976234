import requests from "./httpService";

const PhyicalYearService = {
  getPhysicalYear(id, body) {
    return requests.get(`/settings/all?company_id=${id}`, body);
  },
  getPhysicalYearDetails(id, body) {
    return requests.get(`/settings/details`, body);
  },
  createPhysicalYear(body) {
    return requests.post(`/settings/create`, body);
  },
 updatePhysicalYear(id,body) {
    return requests.post(`settings/update/${id}`, body);
  },
};

export default PhyicalYearService;
