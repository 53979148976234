import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import WorkFlowsService from "../../../Services/WorkFlowsService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import secureLocalStorage from "react-secure-storage";

const WorkflowTasks = () => {
  const navigate = useNavigate();
  const [categoryPackages, setCategoryPackages] = useState([]);

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    const companyID = DataParser.CompanyId();
    WorkFlowsService.WorkflowTasks(id, companyID).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.workflow_tasks;
      setCategoryPackages(data);
    });
  }, []);

  const handelDelete = (e, id) => {
    e.preventDefault();
    const companyID = DataParser.CompanyId();
    const newData = categoryPackages.filter((el) => el.id !== id);
    setCategoryPackages(newData);
    WorkFlowsService.DeleteWorkflowTasks(id, companyID).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Workflow Tasks - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Workflow </h4>
                  <div className="page-title-right">
                    <Link
                      //   to={`/${DataParser.CompanyIdentifierRole()}/departments/create`}
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Create Workflow Tasks
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Name</th>
                            <th>Creation Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categoryPackages &&
                            categoryPackages.map((el) => (
                              <>
                                <tr key={el.id}>
                                  <th scope="row">{el.title}</th>
                                  <td className="tableset">
                                    {moment(el.created_at).format("D/MM/YYYY")}
                                  </td>
                                  <td className="tableset">
                                    <Link
                                      to="#"
                                      onClick={(e) => handelDelete(e, el.id)}
                                      className="invoice-action-view mr-1"
                                    >
                                      <i className="uil-trash iconscolor" />
                                    </Link>
                                    <Link
                                      to={`/${DataParser.CompanyIdentifierRole()}/workflow/task/edit/${
                                        el.id
                                      }`}
                                      className="invoice-action-edit cursor-pointer"
                                    >
                                      <i className="uil-pen iconscolor" />
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkflowTasks;
