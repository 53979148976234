import React, { useState } from "react";

const EditHoliday = () => {
  const [editStatus, setEditStatus] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editDate, setEditDate] = useState(null);
  const [editType, setEditType] = useState(null);
  const [editRepeats, setEditRepeats] = useState(null);
  return <div>EditHoliday</div>;
};

export default EditHoliday;
