import requests from "./httpService";

const CategoryService = {
  getCategories(id, body) {
    return requests.get(`/categories/all?company_id=${id}`, body);
  },
  createCategories(body) {
    return requests.post(`/categories/create`, body);
  },
  deleteCategories(id, body) {
    return requests.delete(`/categories/${id}`, body);
  },
  updateCategories(id, body) {
    return requests.post(`/categories/update/${id}`, body);
  },
  showCategories(id, comid, body) {
    return requests.get(`/categories/${id}`, body);
  },
  updateCategoryStatus(id) {
    return requests.post(`/categories/statusupdate/${id}`);
  },
  updateCategoryDep(id, body) {
    return requests.post(`/categories/updatedepartment/${id}`, body);
  },
  getAllLeadCategoryPackage(id, comId, body) {
    return requests.get(`/category-packages/all/${id}`, body);
  },
  viewLeadCategoryPackage(id, body) {
    return requests.get(`/category-packages/${id}`, body);
  },
  updateLeadCategoryPackage(id, body) {
    return requests.post(`/category-packages/update/${id}`, body);
  },
  createLeadCategoryPackage(id, body) {
    return requests.post(`/category-packages/create/${id}`, body);
  },
  deleteLeadCategoryPackage(id, comid, body) {
    return requests.delete(`/category-packages/${id}`, body);
  },
};

export default CategoryService;
