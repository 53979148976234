import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../Services/authService";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      verifyEmail: email,
    };
    AuthService.forgetPassword(body)
      .then((res) => {
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.error(err ? err.response.data.message : err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  return (
    <>
      <div className="account-pages my-5  pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>
                <a href="index.html" className="mb-5 d-block auth-logo">
                  <img
                    src="/assets/images/logo-dark.png"
                    alt=""
                    height={22}
                    className="logo logo-dark"
                  />
                  <img
                    src="/assets/images/logo-light.png"
                    alt=""
                    height={22}
                    className="logo logo-light"
                  />
                </a>
                <div className="card">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset Password</h5>
                      <p className="text-muted">Reset Password with Minible.</p>
                    </div>
                    <div className="p-2 mt-4">
                      <form onSubmit={handleSubmit}>
                        <div
                          className="alert alert-success text-center mb-4"
                          role="alert"
                        >
                          Enter your Email and instructions will be sent to you!
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="useremail">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="useremail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter email"
                          />
                        </div>
                        <div className="mt-3 text-end">
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Remember It ?&nbsp;
                            <Link to="/" className="fw-medium text-primary">
                              &nbsp; Signin&nbsp;
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    © 2022 Minible. Crafted with&nbsp;
                    <i className="mdi mdi-heart text-danger" /> by Themesbrand
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
    </>
  );
};

export default ForgotPassword;
