import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.min.css";
// import ErrorBoundary from "./ErrorBoundary.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
    <BrowserRouter>
      {/* <ErrorBoundary> */}
      <ToastContainer />
      <App />
      {/* </ErrorBoundary> */}
    </BrowserRouter>
  //</React.StrictMode>
);
