import React from "react";
import secureLocalStorage from "react-secure-storage";
import { Navigate, Outlet } from "react-router-dom";

const PrivateComponent = () => {
  const auth = JSON.parse(secureLocalStorage.getItem("adminInfo"));
  console.log("auth", auth);
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateComponent;
