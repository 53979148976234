import React from "react";
import Avatar from "react-avatar";

const UserProfileHrUser = ({ username, userImage }) => {
  const UserProfile = userImage === undefined || null ? "" : userImage;
  const image = process.env.REACT_APP_USER_IMAGE_PATH + UserProfile;
  return (
    <>
      {UserProfile ? (
        <>
          <div className="profile-img">
            <div className="avatar">
              <img src={image} alt="" />
            </div>
          </div>
        </>
      ) : (
        <>
          <Avatar
            style={{ fontSize: "20px" }}
            name={username}
            maxInitials={2}
            size={80}
            round="100px"
          />
        </>
      )}
    </>
  );
};

export default UserProfileHrUser;
