import React from "react";
import Sidebar from "../../Layouts/Sidebar";

const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <h3 className="text mb-5">Dashboard</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
