import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import PriorityServices from "../../../Services/PriorityServices";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const Priority = () => {
  const [PriorityList, setPriorityList] = useState([]);
  const [userPermissions, setuserPermissions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [priorityName, setPriorityName] = useState("");
  const [priorityNameEdit, setPriorityNameEdit] = useState("");
  const [editID, setEditID] = useState("");
  const companyId = DataParser.CompanyId();

  const navigate = useNavigate();
  const getData = () => {
    setLoading(true);
    PriorityServices.getPriority(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.Priorities;
      setPriorityList(data);
    });
    setLoading(false);
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const AddPackageData = {
      name: priorityName,
      company_id: companyId,
    };
    PriorityServices.createPriority(AddPackageData).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      if (res.Good === true) {
        var link = document.getElementById("PriorityModalCreate");
        link.click();
        getData();
        setPriorityName("");
      }
    });
  };

  useEffect(() => {
    getData();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
  }, []);

  const handleOpenUpadte = (e, item) => {
    setPriorityNameEdit(item.name);
    setEditID(item.id);
  };

  const handelEditSubmit = (e) => {
    const AddData = {
      name: priorityNameEdit,
    };
    PriorityServices.editPriority(editID, AddData).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      if (res.Good === true) {
        var link = document.getElementById("PriorityModalEdit");
        link.click();
        getData();
      }
    });
  };

  const handelDelete = (e, item) => {
    e.preventDefault();
    const id = item.id;
    const dataset = PriorityList.filter((elsss) => elsss.id !== id);
    setPriorityList(dataset);
    const companyId = DataParser.CompanyId();
    PriorityServices.deletePriority(id, companyId)
      .then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
        getData();
      })
      .catch((err) => {});
  };

  return (
    <>
      <Helmet>
        <title>Priority - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Priority</h4>
                  <div className="page-title-right">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      data-bs-toggle="modal"
                      data-bs-target="#createPriorityModal"
                    >
                      Create Priority
                    </button>
                    {/* Create modal content */}
                    <div
                      id="createPriorityModal"
                      className="modal fade"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <form onSubmit={(e) => handelSubmit(e)}>
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Priority
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                id="PriorityModalCreate"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div
                                  className="col-12 mb-3 d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-md-3">
                                    <label
                                      className="form-label"
                                      style={{
                                        paddingRight: 20,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                      }}
                                      htmlFor="Name"
                                    >
                                      Name
                                    </label>
                                  </div>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Name"
                                      placeholder="Name"
                                      required
                                      value={priorityName}
                                      onChange={(e) =>
                                        setPriorityName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                onClick={(e) => handelSubmit(e)}
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                      </form>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                        <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Priority</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            isLoading ? <> <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                            <td>
                              <Skeleton width="40%" />
                            </td>
                          </tr> 
                        </> : <>   {PriorityList &&
                              PriorityList.map((item, index) => (
                                <tr key={item.id}>
                                  <th className="tableset" scope="row">{index + 1}</th>
                                  <td className="tableset">{item.name}</td>
                                  <td className="tableset">
                                    {userPermissions.indexOf("priority-edit") !==
                                    -1 ? (
                                      <Link
                                        to="#"
                                        className="cursor-pointer iconscolor"
                                      >
                                        <i
                                          className="uil-pen iconscolor"
                                          data-bs-toggle="modal"
                                          onClick={(e) =>
                                            handleOpenUpadte(e, item)
                                          }
                                          data-bs-target="#editCategoryModal"
                                        />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    {userPermissions.indexOf(
                                      "priority-delete"
                                    ) !== -1 ? (
                                      <Link
                                        to="#"
                                        className="cursor-pointer iconscolor"
                                        onClick={(e) => handelDelete(e, item)}
                                      >
                                        <i className="uil-trash iconscolor" />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}</>
                          }
                         
                          <div
                            id="editCategoryModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <form onSubmit={(e) => handelEditSubmit(e)}>
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="myModalLabel"
                                    >
                                      Edit Pirority
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      id="PriorityModalEdit"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div
                                        className="col-12 mb-3 d-flex"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div className="col-md-3">
                                          <label
                                            className="form-label"
                                            style={{
                                              paddingRight: 20,
                                              paddingBottom: 0,
                                              marginBottom: 0,
                                            }}
                                            htmlFor="Name"
                                          >
                                            Name
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="Name"
                                            placeholder="Name"
                                            required
                                            value={priorityNameEdit}
                                            onChange={(e) =>
                                              setPriorityNameEdit(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-light waves-effect"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      onClick={(e) => handelEditSubmit(e)}
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Save changes
                                    </button>
                                  </div>
                                </div>
                                {/* /.modal-content modal-dialog-centere */}
                              </div>
                            </form>
                            {/* /.modal-dialog */}
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Priority;
