import { useState, useEffect } from 'react';

export const useLoadMore = (data, limit) => {
    const [items, setItems] = useState(data.slice(0, limit));
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    function loadMore() {
        if (page * limit < data.length) {
            setItems(prevItems => [...prevItems, ...data.slice(page * limit, (page + 1) * limit)]);
            setPage(prevPage => prevPage + 1);
        } else {
            setHasMore(false);
        }
    }

    useEffect(() => {
        setItems(data.slice(0, limit));
        setPage(1);
        setHasMore(true);
    }, [data, limit]);

    return [items, loadMore, hasMore];
}


//how to this in jsx

//const [items, loadMore, hasMore] = useLoadMore(yourDataArray, 10);