import React, { useState } from "react";
import DataParser from "../../utlits/Dataparser";
import UserProfile from "./UserProfile.";

const AssigneField = (props) => {
  const { item, assigne, Services, Owner, newOwner, data, reFreshData } = props;
  const [leadSssigne, setLeadAssine] = useState(newOwner);
  const [leadAssigneName, setLeadAssigneName] = useState(
    `${Owner?.first_name} ${Owner?.last_name}`
  );

  const handelLeadAssigne = (e) => {
    e.preventDefault();
    setLeadAssine(e.target.value);
    const id = item.id;
    const companyId = DataParser.CompanyId();
    const companyIdentifier = DataParser.CompanyIdentifier();
    const leadAssigneInt = parseInt(e.target.value);
    const LeadsData = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      assignee: leadAssigneInt,
    };
    Services(id, LeadsData).then((res) => {
      reFreshData(e);
    });
  };
  return (
    <>
      <UserProfile username={leadAssigneName} userImage={Owner?.image} />
      <select
        className=" form-control sr selectset"
        value={leadSssigne}
        onChange={(e) => handelLeadAssigne(e)}
      >
        {leadSssigne === null ? (
          <>
            <option value="">Please Select</option>
            {assigne &&
              assigne.map((el) => (
                <option key={el.id} value={el.id}>
                  {el?.first_name}&nbsp;
                  {el?.last_name}
                </option>
              ))}
          </>
        ) : (
          <>
            {assigne &&
              assigne.map((el) => (
                <option key={el.id} value={el.id}>
                  {el?.first_name}&nbsp;
                  {el?.last_name}
                </option>
              ))}
          </>
        )}
        {/* <option value="">Please Select</option> */}
      </select>
    </>
  );
};

export default AssigneField;
