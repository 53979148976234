import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const LeaveBadge = (props) => {
  const { item, api } = props;
  const [itemStatus, setItemStatus] = useState(true);

  useEffect(() => {
    const status = item.typ;
    if (status === 1 || itemStatus === true) {
      setItemStatus(true);
    }
    if (status === 0 || itemStatus === false) {
      setItemStatus(false);
    }
  }, []);

  const UpdateStatus = (e, item) => {
    e.preventDefault();
    const id = item.id;
    setItemStatus(!itemStatus);
    api(id);
  };

  return (
    <>
      <Link to="#" onClick={(e) => UpdateStatus(e, item)}>
        {itemStatus === true ? (
          <span className="btn btn-success waves-effect waves-light btn-sm">
            Paid
          </span>
        ) : (
          <span className="btn btn-danger waves-effect waves-light btn-sm">
            Unpaid
          </span>
        )}
      </Link>
    </>
  );
};

export default LeaveBadge;
