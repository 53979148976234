import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const CreateHoliday = () => {
  const navigate = useNavigate();

  const [status, setStatus] = useState(true);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("Full Day");
  const [repeats, setRepeats] = useState(false);

  useEffect(() => {
    const datee = DataParser.getDateinFormat();
    setDate(datee);
  }, []);
  const onDateChange = (date) => {
    setDate(date);
  };

  const onSubmitHandler = (e) => {};

  const onRepeathange = () => {};
  const onStatusChange = () => {};

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#navtabs-home"
                          role="tab"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                            <i className="uil-user" />
                            &nbsp;General
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content p-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="navtabs-home"
                        role="tabpanel"
                      >
                        <form onSubmit={(e) => onSubmitHandler(e)}>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-6">
                                <label className="form-label" htmlFor="Name">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Name"
                                  placeholder="Title"
                                  required
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                              <div className="col-6">
                                <label className="form-label" htmlFor="Name">
                                  Date
                                </label>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                              <button
                                type="submit"
                                className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                              >
                                Save changes
                              </button>
                              <button
                                type="reset"
                                className="btn btn-light"
                                onClick={() => navigate(-1)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateHoliday;
