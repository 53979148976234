import requests from "./httpService";

const JobStatusService = {
  getJobStatusService(body) {
    return requests.get(`/jobstatuses/all?company_id=${body}`, body);
  },
  updateJobStatusStatus(id, body) {
    return requests.post(`/jobstatuses/statusupdate/${id}`, body);
  },
  createJobStatus(body) {
    return requests.post(`/jobstatuses/create`, body);
  },
  editJobStatus(id, body) {
    return requests.post(`/jobstatuses/update/${id}`, body);
  },
  deleteJobStatus(id, body) {
    return requests.delete(`/jobstatuses/${id}`, body);
  },
};

export default JobStatusService;
