import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CompanyService from "../../../Services/CompanyService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const animatedComponents = makeAnimated();

const CreatePackage = () => {
  const [Title, setTitle] = useState("");
  const [Price, setPrice] = useState("");
  const [Modules, setModules] = useState([]);
  const [Description, setDescription] = useState("");

  const navigate = useNavigate();

  const options = [
    { value: "task", label: "Task" },
    { value: "leads", label: "Leads" },
    { value: "projects", label: "Projects" },
  ];

  const onModuleSelect = (e) => {
    const data = e;
    const values = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i].value;
      values.push(element);
    }
    setModules(values);
  };

  const handleCreatePackage = (e) => {
    e.preventDefault();
    const Data = {
      name: Title,
      price: Price,
      description: Description,
      modules: Modules,
    };
    CompanyService.createCompanyPackages(Data).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      if (res.Good === true) {
        navigate(`/${DataParser.CompanyIdentifierRole()}/packages`);
      }
    });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Package</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      className="needs-validation"
                      noValidate
                      onSubmit={(e) => handleCreatePackage(e)}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom01"
                            >
                              Package Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="Package Title"
                              value={Title}
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              Please Add a valid Title.
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom02"
                            >
                              Price
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="validationCustom02"
                              placeholder="Price"
                              value={Price}
                              onChange={(e) => setPrice(e.target.value)}
                              required
                            />
                            <div className="valid-feedback">Looks good!</div>
                            <div className="invalid-feedback">
                              Please Add a valid Price.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom03"
                            >
                              Modules
                            </label>
                            <Select
                              options={options}
                              components={animatedComponents}
                              onChange={onModuleSelect}
                              isMulti
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustom04"
                            >
                              Description
                            </label>
                            <textarea
                              id="basicpill-address-input"
                              className="form-control"
                              rows="2"
                              value={Description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <button className="btn btn-primary" type="submit">
                        Save Changes
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePackage;
